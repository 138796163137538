<template>
  <div class="analyse-popup-chart">
    <div class="chart-popup-search">
        {{showLang('com.date.start')}}：
      <DatePicker v-model="form.start" type="datetime"  style="width: 180px; margin-right: 10px;"></DatePicker>
        {{showLang('com.date.end')}}：
      <DatePicker v-model="form.end" type="datetime"  style="width: 180px; margin-right: 10px;"></DatePicker>
      <Button type="info" @click="loadChert" style="margin-right: 10px;">{{showLang('com.op.query')}}</Button>
    </div>
    <div class="chart-popup-chart">
      <chartsLine :option="chartsLineData" id="lightingRate2" :key="timer"></chartsLine>
    </div>
    <div class="chart-popup-table">
      <u-table :data="tabData" use-virtual showBodyOverflow="title" showHeaderOverflow="title" height="300" stripe>
        <u-table-column type="index" width="100"></u-table-column>
        <u-table-column prop="time" :label="showLang('com.date.time')" sortable fit></u-table-column>
        <u-table-column prop="value" :label="showLang('com.stat.light.rate')" sortable fit>
          <template slot-scope="params">
            {{img.val(params.row.value)}}%
          </template>
        </u-table-column>
      </u-table>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex'
import { setCookie, getCookie } from '@/plugins/cookie'
Vue.prototype.$cookieStore = { setCookie, getCookie }
export default {
  name: 'DataAnalysis',
  components: {
  },
  props: {
    tabNameType: {
      type: String,
      default: "power",
    }
  },
  data() {
    return {
      form: {
        start: '',
        end: '',
      },
      energyData: [
        { name: '2021-09-15', value: 0 },
        { name: '2021-09-16', value: 0 },
        { name: '2021-09-17', value: 0 },
        { name: '2021-09-18', value: 0 },
        { name: '2021-09-19', value: 0 },
        { name: '2021-09-20', value: 0 },
        { name: '2021-09-21', value: 0 },
        { name: '2021-09-22', value: 0 },
      ],
      timer: "",
      //亮灯率
      chartsLineData: {
        color: ["#02CBB1"],
        xColor: "#B5C1CC",
        yColor: "#99A8B6",
        title: "",
        x: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun5"],
        unit: "%",
        series: [
          {
            name: "亮灯率",
            data: [0, 0, 0, 0, 0, 0, 0],
          },
        ],
        setSeries: {
          smooth: true, //平滑折线图
          areaStyle: {
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: "#02CBB1", // 0% 处的颜色
                },
                {
                  offset: 0.6,
                  color: "rgba(2,205,177,0.5)", // 100% 处的颜色
                },
                {
                  offset: 0.9,
                  color: "rgba(2,205,177,0.2)", // 100% 处的颜色
                },
              ],
              global: false, // 缺省为 false
            },
          }, //下阴影
        },
      },
      timeout: null,
      tabData: [],
    }
  },
  computed: {
    ...mapState('cmd', ['cmds', 'cmdResultRefresh', 'windowOnResize', 'lightCmds']),
    ...mapGetters('group', ['groupPathName']),
    ...mapGetters('auth', ['showLang']),
    ...mapState('common', ['weeks', 'productCodes', 'powerBoxTypes', 'lightTopDevices', 'lightMonitorDevices']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
    ...mapState('auth', ['stationTabName']),
  },
  watch: { },
  mounted: function () {
    let now = new Date();
    this.form.end = now.format('yyyy-MM-dd HH:mm:ss');
    now.setDate(now.getDate() - 7);
    this.form.start = now.format('yyyy-MM-dd HH:mm:ss');
    this.widthChange();
    this.debounceInitData();
  },
  methods: {
    debounceInitData: function () {
      this.widthChange();
      clearTimeout(this.timeout);
      this.timeout = setTimeout(this.loadChert, 500);
    },
    loadChert() {
      this.lightingRate();//亮灯率
    },
    lightingRate: async function () {
      let end = new Date(this.form.end).format("yyyy-MM-dd");
      let start = new Date(this.form.start).format("yyyy-MM-dd");
      let ajaxData = { groupId: 0, stationId: 0, start: start, end: end };
      switch (this.selectedNode.type) {
        case "root":
          ajaxData.groupId = 0;
          ajaxData.stationId = 0;
          break;
        case "group":
          ajaxData.groupId = this.selectedNode.id;
          ajaxData.stationId = 0;
          break;
        case "Station":
          ajaxData.groupId = 0;
          ajaxData.stationId = this.selectedDevices.map(p => p.id)[0];
          break;
      }
      let res = await this.$axios.post(`//${this.domains.trans}/station/analyse/LightOnTrend`, ajaxData);
      if (res.code != 0) {
        return false;
      }
      this.chartsLineData.x = [];
      this.chartsLineData.series[0].data = [];
      res.data.forEach(el => {
        el.value = (el.value * 1).toFixed(2);
        this.chartsLineData.x.push(el.time);
        this.chartsLineData.series[0].data.push(el.value)
      });
      this.tabData = [];
      this.$set(this, 'tabData', res.data);
      this.widthChange();
    },
    widthChange() {
      this.timer = new Date().getTime();
    },
  }
}
</script>
<style scoped>
.analyse-popup-chart {
  display: flex;
  margin-bottom: 20px;
  flex-direction: column;
}
.chart-popup-chart {
  width: 100%;
  height: 300px;
}
.chart-popup-table {
  margin-top: 20px;
  width: 100%;
  height: 300px;
}
</style>