<template>
<div class="alarm-offline-container">
  <div class="offline-data-area" ref="table1">
    <u-table :data="list" style="width: 100%;" row-key="id" border stripe :height="tabHeight" default-expand-all>
      <u-table-column type="index" :label="showLang('com.export.cn.seq')" width="55"></u-table-column>
      <u-table-column prop="stationId" :label="showLang('com.site.id')" width="80">
        <template slot-scope="params">
          {{params.row.stationId}}
        </template>
      </u-table-column>
      <u-table-column prop="stationName" :label="showLang('com.site.name')" sortable width="150">
        <template slot-scope="params">
          {{params.row.stationName}}
        </template>
      </u-table-column>
      <u-table-column prop="offlineTime" :label="showLang('com.date.offline')" sortable width="180">
        <template slot-scope="params">
          {{params.row.offlineTime}}
        </template>
      </u-table-column>
      <u-table-column prop="offlineTime" :label="showLang('com.date.offline.diff')" sortable>
        <template slot-scope="params">
          {{getTimeDiff(params.row.offlineTime) }}
        </template>
      </u-table-column>
    </u-table>
  </div>
</div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'AlarmOfflineIndex',
  components:{
  },
  props: {
    args: {
      type: Object,
      default(){return {}}
    }
  },
  data () {
    return {
      list: [],
      tabHeight: 200,
      editItem: {isAdd: true, data: {}},
      showEditModal: false,
      curTab: 'hour3',
    }
  },
  computed: {
    ...mapGetters('auth', ['showLang']),
    ...mapState('cmd', ['windowOnResize', 'cmds']),
    ...mapState('common', ['weeks', 'productCodes', 'alarmTypes', 'alarmLevels', 'dealAlarmTypes']),
    ...mapGetters('common', ['getDealAlarmMethod']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
    selectedStationIds: function(){
      let pids = this.selectedDevices.filter(p => p.type == this.productCodes.station).map(p => {return p.id});
      return pids;
    },
  },
  watch: {
    windowOnResize(){
      this.setTableHeight();
    },
    selectedNode(){
      this.getList();
    }
  },
  mounted: function(){
    // console.log('get args', this.args);
    setTimeout(this.setTableHeight, 100);
    this.getList();
  },
  destroyed: function(){
  },
  methods: {
    getTimeDiff: function(time){
      if(time == '-')return '-';
      var t = new Date(time);
      var diff = parseInt((new Date().getTime() - t.getTime()) / 60000);
      // console.log('get diff mins', new Date().getTime(), t.getTime(), diff);
      var days = parseInt(diff / 1440);
      diff -= days * 1440;
      var hours = parseInt(diff / 60);
      diff -= hours * 60;
      return this.showLang('com.date.offline.format', days, hours, diff);
    },
    setTableHeight: function(){
      this.tabHeight = this.$refs.table1.clientHeight
    },
    handleAdd: function(){
      this.editItem = {isAdd: true, data: {}};
      this.showEditModal = true;
    },
    handleEdit: function(params){
      this.editItem = {isAdd: false, data: params.row};
      this.showEditModal = true;
    },
    itemSaved: function(){
      this.getList();
    },
    getList: function(){
      let groupId = 0, stationId = 0;
      if(this.selectedNode.type == 'root' || this.selectedNode.type == 'group'){
        groupId = this.selectedNode.id;
      }else{
        stationId = this.selectedNode.id;
      }
      // console.log('windowOnResize', this.selectedStationIds.join(','))
      let params = {groupId, stationId};
      this.$axios.post(`//${this.domains.trans}/station/alarm/QueryOffline`, params).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$set(this, 'list', res.data);
        }
      });
    },
  }
}
</script>
<style scoped>
.alarm-offline-container{
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  /* padding-right: 40px; */
  /* border: solid 1px red; */
}
.offline-data-query{
  height: 40px;
  flex: none;
  display: flex;
  align-items: center;
  padding-left: 10px;
}
.offline-data-area{
  /* border: solid 1px red; */
  height: 100px;
  flex: auto;
  /* border: solid 1px #DCDEE2; */
  border-radius: 6px;
}
.station-alarm-tabs{
  height: 30px;
  flex: none;
  display: flex;
}
.station-alarm-tab{
  padding: 3px 8px;
  margin: 3px 3px 0 3px;
  border: solid 1px lightgray;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
  background-color: white;
}
.tab-active{
  border: solid 1px rgb(135, 159, 224);
  background-color: rgb(135, 159, 224);
  color: white;
}
.station-alarm-tabcontent{
  height: 500px;
  flex: auto;
  /* border: solid 1px red; */
}
/* .box-body{
  height: 0;
  flex: auto;
  margin-top: 5px;
  border: solid 1px red;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: relative;
}
.box-body::-webkit-scrollbar {
  display: none;
} */
</style>