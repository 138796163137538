<template>
  <div class="analyse-popup-chart">
    <div class="chart-popup-search">
      {{showLang('com.date.start')}}：
      <DatePicker v-model="form.start" type="datetime" style="width: 180px; margin-right: 10px;"></DatePicker>
       {{showLang('com.date.end')}}：
      <DatePicker v-model="form.end" type="datetime" style="width: 180px; margin-right: 10px;"></DatePicker>
      <Button type="info" @click="loadChert" style="margin-right: 10px;">{{showLang('com.op.query')}}</Button>
    </div>
    <div class="chart-popup-chart">
      <chartsBar :option="chartsBarData" id="chartsBarData" :key="timer"></chartsBar>
    </div>
    <div class="chart-popup-table">
      <table cellspacing="0" cellpadding="0" border="1" class="popup-table-style" width="100%">
        <template v-if="tabData.length>0">
          <tr>
            <td v-for="(item) in tabData" :key="item.type" align="center">
              {{item.name}}
            </td>
          </tr>
          <tr>
            <td v-for="(item) in tabData" :key="item.type" align="center">
              {{item.cnt}}
            </td>
          </tr>
        </template>
        <template v-else>
          <tr>
            <td align="center">
             {{showLang('com.data.no')}}
            </td>
          </tr>
        </template>
      </table>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex'
import { setCookie, getCookie } from '@/plugins/cookie'
Vue.prototype.$cookieStore = { setCookie, getCookie }
export default {
  name: 'DataAnalysis',
  components: {
  },
  props: {
    tabNameType: {
      type: String,
      default: "power",
    }
  },
  data() {
    return {
      timer: "",
      policeTypeData: [],
      policeLevels: [0, 0, 0,],
      timeout: null,
      tabData: [],
      chartsBarData: {
        title: '',
        x: [],
        unit: '',
        series: [
          {
            name: '报警',
            data: [],
          }
        ],
        setSeries: {
          barWidth: 15,
          showBackground: true, //带背景色
          backgroundStyle: {
            color: "rgba(180, 180, 180, 0.2)",
          },
          label: {//柱上显值
            show: true,
            position: "top",
          },
        }
      },
      form: {
        start: '',
        end: '',
      },
    }
  },
  computed: {
    ...mapState('cmd', ['cmds', 'cmdResultRefresh', 'windowOnResize', 'lightCmds']),
    ...mapGetters('group', ['groupPathName']),
    ...mapGetters('auth', ['showLang']),
    ...mapState('common', ['weeks', 'productCodes', 'powerBoxTypes', 'lightTopDevices', 'lightMonitorDevices']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
    ...mapState('auth', ['stationTabName']),
  },
  watch: {
  },
  mounted: function () {
    let now = new Date();
    this.form.end = now.format('yyyy-MM-dd HH:mm:ss');
    now.setDate(now.getDate() - 7);
    this.form.start = now.format('yyyy-MM-dd HH:mm:ss');
    this.widthChange();
    this.debounceInitData();
  },
  methods: {
    debounceInitData: function () {
      this.widthChange();
      clearTimeout(this.timeout);
      this.timeout = setTimeout(this.loadChert, 500);
    },
    loadChert() {
      this.policeType();//报警统计
    },
    policeType: async function () {
      let res = await this.$axios.get(`//${this.domains.trans}/station/alarm/type`);
      if (res.code !== 0) {
        // this.$Message.error(res.data);
        return;
      }
      this.policeTypeData = res.data;
      this.policeAjax();
    },
    policeAjax: async function () {
      this.form.end = new Date(this.form.end).format("yyyy-MM-dd HH:mm:ss");
      this.form.start = new Date(this.form.start).format("yyyy-MM-dd HH:mm:ss");
      let ajaxData = { groupId: 0, stationId: 0, type: this.stationTabName, start: this.form.start, end: this.form.end };
      switch (this.selectedNode.type) {
        case "root":
          ajaxData.groupId = 0;
          ajaxData.stationId = 0;
          break;
        case "group":
          ajaxData.groupId = this.selectedNode.id;
          ajaxData.stationId = 0;
          break;
        case "Station":
          ajaxData.groupId = 0;
          ajaxData.stationId = this.selectedDevices.map(p => p.id)[0];
          break;
      }
      let res = await this.$axios.post(`//${this.domains.trans}/station/analyse/AlarmMore`, ajaxData);
      if (res.code !== 0) {
        // this.$Message.error(res.data);
        return;
      }
      res.data.types = this.typeAdd(res.data.types);
      this.$set(this, 'tabData', res.data.types);
      this.chartsBarData.x = [];
      this.chartsBarData.series[0].data = [];
      res.data.types.forEach(el => {
        this.chartsBarData.x.push(el.name);
        this.chartsBarData.series[0].data.push(el.cnt)
      });
      this.policeLevels[0] = res.data.levels[0] != undefined ? res.data.levels[0].cnt : ""
      this.policeLevels[1] = res.data.levels[1] != undefined ? res.data.levels[1].cnt : ""
      this.policeLevels[2] = res.data.levels[2] != undefined ? res.data.levels[2].cnt : "";
      // let newData = JSON.parse(JSON.stringify(res.data.types))
      this.widthChange()
    },
    typeAdd(data) {
      for (let i = 0; i < data.length; i++) {
        data[i].value = data[i].cnt;
        let name = this.policeTypeData[data[i].type];
        data[i].name = name;
      }
      return data;
    },
    widthChange() {
      this.timer = new Date().getTime();
    },
  }
}
</script>
<style scoped>
.analyse-popup-chart {
  display: flex;
  margin-bottom: 20px;
  flex-direction: column;
}
.chart-popup-chart {
  width: 100%;
  height: 300px;
}
.chart-popup-table {
  margin-top: 20px;
  width: 100%;
  height: 300px;
}
.popup-table-style th,
.popup-table-style td {
  padding: 8px 5px;
}
</style>