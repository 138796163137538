<template>
  <div class="power-config-container">
    <div class="power-config-tabs">
      <template v-for="(item, idx) in tabs">
        <div :key="idx" v-if="canOp(item.opCode)" :class="['power-config-tab', curTab == item.code ? 'tab-active' : '']" @click="switchTab(item.code)">{{showLang(item.lang)}}</div>
      </template>
    </div>
    <div class="power-config-tabcontent">
      <template v-for="(item, idx) in tabs">
        <component v-if="item.code == curTab  && canOp(item.opCode)" :key="idx" v-bind:is="item.com" :args="item.args" :stationId="currentStationId" @stationType="obtainStationId"></component>
      </template>
    </div>
  </div>
</template>
<script>
import { mapState,mapGetters } from 'vuex'
// import AlarmLevel from './level/Index'
// import AlarmRule from './rule/Index'
import AlarmSetting from './setting/Index'
// import AlarmTypes from './type/Index'
import StationAlarmTypes from './type/Station'
import LightAlarmTypes from './type/Light'
import AlarmNotify from './notify/Index'
// import AlarmDuty from '@/components/config/alarm/duty/Index'
import OrganMaintance from '@/components/work/organ/maintance/Index'
// import AlarmDone from './done/Index'
export default {
  name: 'StationIndex',
  components: {
  },
  props: {
  },
  data() {
    return {
      curTab: 's4',
      currentStationId: 0,
      tabs: [
        { code: 's4', name: '配电柜告警类型设置', lang:'alarm.station.type.set',com: StationAlarmTypes, args: {}, opCode: ["atv1",'ate1'] },//opCode 判断查看功能
        { code: 's5', name: '单灯告警类型设置', lang:'alarm.light.type.set',com: LightAlarmTypes, args: {}, opCode: ["atv1",'ate1'] },//opCode 判断查看功能
        { code: 's2', name: '告警通知设置', lang:'alarm.nav.notice.set',com: AlarmNotify, args: {}, opCode: ["anv",'ane'] },//opCode 判断查看功能
        // { code: 's4', name: '告警消除设置', lang:'alarm.nav.done.set',com: AlarmDone, args: {}, opCode: ["adv",'ade'] },//opCode 判断查看功能
        // { code: 's10', name: '告警拦截设置', lang:'alarm.nav.intercept.set',com: AlarmRule, args: {}, opCode: ["arv",'ara','are','ard'] },//opCode 判断查看功能
        // { code: 's11', name: '告警等级配置', lang:'alarm.nav.level.set',com: AlarmLevel, args: {}, opCode: ["alv",'ale'] },//opCode 判断查看功能
        { code: 's3', name: '值班管理', lang:'alarm.nav.duty.manage',com: OrganMaintance, args: {}, opCode: ["aov",'aoa','aoe','aod'] },//opCode 判断查看功能
        { code: 's1', name: '告警基本设置', lang:'alarm.nav.basic.set',com: AlarmSetting, args: {}, opCode: ["abv",'abe'] },//opCode 判断查看功能
      ],
    }
  },
  computed: {
    ...mapGetters('auth', ['showLang']),
    ...mapState('auth', ['appType', 'user', 'ops']),
    ...mapState('group', ['groups', 'selectedNode', 'selectedDevices']),
    ...mapState('cmd', ['windowOnResize']),
    ...mapState('common', ['currentChannelCount', 'leakageChannelCount', 'steelChannelCount', 'weeks', 'deviceTypes', 'productCodes', 'powerBoxTypes']),
  },
  watch: {
  },
  mounted: function () {
  },
  destroyed: function () {
  },
  methods: {
    canOp(opCode) {
      if (this.user && this.user.isSuper) return true;
      // if (this.ops && this.ops.indexOf(opCode) != -1) return true;
      if(opCode.length==0) return true;
      for (let index = 0; index < opCode.length; index++) {
        if (this.ops && this.ops.indexOf(opCode[index]) != -1) {
          return true;
        }
      }
      return false;
    },
    obtainStationId(id) {
      this.currentStationId = id;
    },
    switchTab: function (type) {
      this.curTab = type;
    },
  }
}
</script>
<style scoped>
.power-config-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  /* border: solid 1px red; */
  /* padding: 3px 10px; */
}
.power-config-tabs {
  height: 30px;
  flex: none;
  display: flex;
}
.power-config-tab {
  padding: 3px 8px;
  margin: 3px 3px 0 3px;
  border: solid 1px lightgray;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
  background-color: white;
}
.tab-active {
  border: solid 1px rgb(135, 159, 224);
  background-color: rgb(59, 170, 127);
  color: white;
}
.power-config-tabcontent {
  height: 500px;
  flex: auto;
  border-top: solid 1px rgba(68, 68, 68, 0.158);
  overflow: hidden;
}
/* .box-body{
  height: 0;
  flex: auto;
  margin-top: 5px;
  border: solid 1px red;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: relative;
}
.box-body::-webkit-scrollbar {
  display: none;
} */
</style>