<template>
  <div class="search-data-container">
    <div class="search-data-header">
      <div class="search-data-query">
        {{showLang('com.device.box.own')}}：
        <Input type="text" v-model="form.name" clearable style="width: 150px; margin: 0 10px;"></Input>
        <!-- <Select :placeholder="showLang('save.select')" v-model="form.stationId"
          style="width: 150px; margin-right: 10px;">
          <template v-for="(item, idx) in selectedStations">
            <Option :value="item.id" :key="idx">{{item.name}}</Option>
          </template>
        </Select> -->
        {{showLang('com.ins.last.id')}}：
        <Input type="text" v-model="form.code" clearable style="width: 150px; margin: 0 10px;"></Input>
        {{showLang('com.ins.name')}}：
        <Input type="text" v-model="form.cmd" clearable style="width: 150px; margin: 0 10px;">
        </Input>
        {{showLang('com.date.start')}}：
        <DatePicker v-model="form.start" type="datetime" style="width: 170px; margin-right: 10px;"></DatePicker>
        {{showLang('com.date.end')}}：
        <DatePicker v-model="form.end" type="datetime" style="width: 170px; margin-right: 10px;"></DatePicker>
        <Button v-if="funCodes('sccv1')" type="info" :loading="loading" @click="getList(true)" style="margin-right: 10px;">{{showLang('com.op.query')}}</Button>
        <Button type="success" :loading="exportLoading" @click="exportData" style="margin-right: 10px;">{{showLang('com.op.export')}}</Button>
      </div>
    </div>
    <div class="search-data-content" ref="table1">
      <vxe-table :empty-text="showLang('com.data.no')" :loading="loading" border resizable show-overflow
        show-header-overflow keep-source ref="chkTable" :data="tabData" :height="tabHeight"
        :row-config="{isHover: true}" :checkbox-config="{checkField: 'checked',}"
        footer-cell-class-name="footer-cell-class">
        <vxe-column type="seq" width="60"></vxe-column>
        <vxe-column field="sname" :title="showLang('com.site.affiliated')" width="150" header-align="center">
          <template #default="{ row }">
            {{row.sname}}
          </template>
        </vxe-column>
        <vxe-column field="name" :title="showLang('com.export.cn.connid')" width="150" header-align="center">
          <template #default="{ row }">
            {{row.dcode}}
          </template>
        </vxe-column>
        <vxe-column field="createTime" :title="showLang('com.date.Creation')" width="150" header-align="center">
          <template #default="{ row }">
            {{row.createTime}}
          </template>
        </vxe-column>
        <vxe-column field="sendTime" :title="showLang('com.lighting.sending.time')" width="150" header-align="center">
          <template #default="{ row }">
            {{row.sendTime==''?'-':row.sendTime}}
          </template>
        </vxe-column>
        <vxe-column field="pname" :title="showLang('com.ins.name')" width="120" header-align="center">
          <template #default="{ row }">
            {{row.name}}
          </template>
        </vxe-column>
        <vxe-column field="type" :title="showLang('com.ins.source')" width="120" header-align="center">
          <template #default="{ row }">
            {{row.type}}
          </template>
        </vxe-column>
        <vxe-column field="sourceDescription" :title="showLang('com.illustrate')" width="120" header-align="center">
          <template #default="{ row }">
            {{row.sourceDescription}}
          </template>
        </vxe-column>
        <vxe-column field="checkUserName" :title="showLang('com.user.check')" width="120" header-align="center">
          <template #default="{ row }">
            {{row.checkUserName}}
          </template>
        </vxe-column>
        <vxe-column field="args" :title="showLang('com.parameter')" width="120" header-align="center">
          <template #default="{ row }">
            {{row.args}}
          </template>
        </vxe-column>
        <vxe-column field="replyTime" :title="showLang('com.date.recovery')" width="150" header-align="center">
          <template #default="{ row }">
            {{row.replyTime}}
          </template>
        </vxe-column>
        <vxe-column field="message" :title="showLang('com.ins.result')" header-align="center">
          <template #default="{ row }">
            {{row.message}}
          </template>
        </vxe-column>
      </vxe-table>
      <vxe-pager :loading="loading" :current-page="form.index" :page-size="form.size" :total="count" :page-sizes='pageSizes'
        :layouts="['PrevPage', 'JumpNumber', 'NextPage', 'FullJump', 'Sizes', 'Total']"
        @page-change="handlePageChange1">
      </vxe-pager>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'StationConfigIndex',
  components: {
  },
  props: {
  },
  data() {
    return {
      loading: false,
      curTab: 'group',
      tabHeight: 1,
      tabData: [//列表数据
      ],
      exportLoading: false,
      pageSizes:[20,100,500,1000,5000],
      tabHead: { //头部标题
        sname: "站点名称",
        pname: "属性名称",
        createTime: '创建时间',
        sendTime: '发送时间',
        type: '指令来源',
        sourceDescription: '来源说明',
        name: "指令名称",
        args: '参数内容',
        message: '执行结果'
      },
      count: 0,
      form: {
        // stationId: 0,
        name: '',
        start: "",
        end: "",
        code: '',
        cmd: '',
        index: 1,
        size: 20,
      },
      history: [],
      timeTables: [],
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes', 'showLang']),
    ...mapState('cmd', ['windowOnResize', 'cmds']),
    ...mapState('common', ['weeks', 'productCodes', 'alarmTypes', 'alarmLevels']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
    selectedStations: function () {
      return this.selectedDevices.filter(p => p.type == this.productCodes.station);
    },
    selectedStationIds: function () {
      let pids = this.selectedDevices.filter(p => p.type == this.productCodes.station).map(p => { return p.id });
      return pids;
    },
  },
  watch: {
    viewCode() {
      this.createColAndData();
    },
    // selectedNode() {
    //   this.setDefaultStation();
    // },
    windowOnResize() {
      this.setTableHeight();
    },
  },
  mounted: function () {
    let now = new Date();
    this.form.end = now.format('yyyy-MM-dd HH:mm:ss');
    now.setDate(now.getDate() - 3);
    this.form.start = now.format('yyyy-MM-dd HH:mm:ss');
    setTimeout(() => {
      this.setTableHeight();
    }, 100);
    this.getUseablePlan();
    // this.setDefaultStation();
    // this.getList();
  },
  destroyed: function () {
  },
  methods: {
    exportData: function(){
      this.form.start = new Date(this.form.start).format("yyyy-MM-dd HH:mm:ss");
      this.form.end = new Date(this.form.end).format("yyyy-MM-dd HH:mm:ss");
      let params = {
        name: this.form.name,
        // sid: this.form.stationId,
        start: this.form.start, 
        end: this.form.end, 
        code: this.form.code, 
        cmd: this.form.cmd, 
        index: this.form.index, 
        size: this.form.size 
      };
      this.$axios.request({
        url: `//${this.domains.trans}/station/search/exportcmdlog`,
        method: 'post',
        data: params,
        responseType: 'blob'
      }).then(res => {
        let url = window.URL.createObjectURL(res);
        var a = document.createElement('a')
        document.body.appendChild(a)
        a.href = url
        a.download = `指令下发记录.xlsx`
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url)
      })
    },
    getUseablePlan: function () {
      this.$axios.post(`//${this.domains.trans}/station/plan/QueryChannelPlan`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this, 'timeTables', res.data);
        }
      });
    },
    setTableHeight: function () {
      this.tabHeight = this.$refs.table1.clientHeight - 50;
    },
    // setDefaultStation: function () {
    //   if (this.selectedStations.length == 0) {
    //     this.form.stationId = 0;
    //     return;
    //   }
    //   if (this.form.stationId == 0) {
    //     this.form.stationId = this.selectedStations[0].id;
    //     return;
    //   }
    //   let ss = this.selectedStations.filter(p => p.id == this.form.stationId)
    //   if (ss.length == 0) {
    //     this.form.stationId = this.selectedStations[0].id;
    //   }
    // },
    handlePageChange1: function (params) {
      this.form.index = params.currentPage
      this.form.size = params.pageSize
      this.getList()
    },
    getList(is) {
      this.form.start = new Date(this.form.start).format("yyyy-MM-dd HH:mm:ss");
      this.form.end = new Date(this.form.end).format("yyyy-MM-dd HH:mm:ss");
      let params = {
        name: this.form.name,
        // sid: this.form.stationId,
        start: this.form.start, 
        end: this.form.end, 
        code: this.form.code, 
        cmd: this.form.cmd, 
        index: this.form.index, 
        size: this.form.size 
      };
      if (is) { params.index = 1 }
      this.loading = true;
      this.$axios.post(`//${this.domains.trans}/station/search/cmdlog`, params).then(res => {
        this.loading = false;
        if (res.code == 0) {
          res.data.list.map(item => {
            item.type = item.type == 0 ? this.showLang('auth.user') : this.showLang('auth.system.plan')
            item.args = this.getArgs(item.code, item.arguments);
            item.sendTime = new Date(item.sendTime).getFullYear() == 1970 ? '-' : new Date(item.sendTime).format("yyyy-MM-dd HH:mm:ss");
            item.replyTime = new Date(item.replyTime).getFullYear() == 1970 ? '-' : new Date(item.replyTime).format("yyyy-MM-dd HH:mm:ss");
            item.dcode = item.dcode == '' ? this.showLang('com.export.dev.del') : item.dcode;
          })
          this.$set(this, 'tabData', res.data.list);
          // console.log(res.data.list)
          this.count = res.data.count;
          this.createColAndData();
        }
      });
    },
    getArgs: function (code, args) {
      switch (code) {
        case 'handControl': return this.showLang('com.ins.ch') + ` K${args.channel} ${args.open ? this.showLang('com.but.open.lamp') : this.showLang('com.but.close.lamp')} ${this.showLang('com.date.to')} [${args.expire}]`;
        case 'getYearPlan': return this.showLang('com.ins.ch.read.schedule', args.channel, args.month, args.day);
        case 'setYearPlan': {
          let ts = this.timeTables.filter(p => p.id == args.timeId);
          let tname = '';
          if (ts.length == 0) {
            tname = this.showLang('com.op.del2');
          } else {
            tname = ts[0].name;
          }
          return this.showLang('com.ins.ch') + `K${args.channel} ${this.showLang('com.but.set.time.tab')} [${tname}]`;
        }
        case 'getCurrentRate': return this.showLang('com.ope.nav.get.i.ch') + `C${args.channel}` + this.showLang('com.lighting.cat');
        case 'setCurrentRate': return this.showLang('com.ope.nav.set.i.ch') + `C${args.channel} ${this.showLang('com.lighting.cat')} ${args.k}`;
        default: return '-';
      }

    },
    createColAndData: function () {
    },
    switchTab: function (type) {
      this.curTab = type;
    },
  }
}
</script>
<style scoped>
.search-data-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  /* border: solid 1px blue; */
}
.search-data-header {
  height: 40px;
  flex: none;
  display: flex;
  /* border: solid 1px blue; */
}
.search-data-content {
  height: 100px;
  flex: auto;
  /* border: solid 1px blue; */
}
.search-data-query {
  width: 830px;
  flex: auto;
  background-color: white;
  /* margin: 3px 1px; */
  display: flex;
  align-items: center;
  padding: 10px;
  /* border: solid 1px blue; */
  white-space: nowrap;
}
.search-data-view {
  width: 300px;
  flex: auto;
  display: flex;
  align-items: center;
  /* border: solid 1px blue; */
  white-space: nowrap;
  padding: 10px;
}
</style>