<template>
  <div class="station-config-container">
    <div class="station-config-tabs">
      <div v-for="(item, idx) in tabs" :key="idx" :class="['station-config-tab', curTab == item.code ? 'tab-active' : '']"
        @click="switchTab(item.code)">{{showLang(item.lang)}}</div>
    </div>
    <div class="station-config-tabcontent">
      <template v-for="(item, idx) in tabs">
        <component v-if="item.code == curTab" :key="idx" v-bind:is="item.com" :args="item.args"></component>
      </template>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import GroupList from '@/components/config/station/group/Index'
import StationList from '@/components/config/station/station/Index'
import DeviceList from '@/components/config/station/device/Index'
import TimeIndex from '@/components/config/timetable/Index'
import AlarmIndex from '@/components/config/alarm/Index'
// import LightList from '@/components/config/light/Index'
import PowerList from './power/Index'
// import PowerList from './box/Index'
import LightList from './light/Index'
// import LuxList from './lux/Index'
// import LuxRuleItem from './luxrule/Index'
// import TimeIndex from './time/Index'
// import AlarmIndex from './alarm/Index'
// import ConfigAccountIndex from '@/views/common/account/Index'
// import currencyIndex from '@/views/common/currency/Index'

import setMap from './map/Index'
export default {
  name: 'StationConfigIndex',
  components: {
  },
  props: {
  },
  data() {
    return {
      curTab: 's7',
      tabs: [
        { code: 's7', name: '分组管理', lang: 'manage.nav.group', com: GroupList, args: { start: 0, end: 3 } },
        { code: 's8', name: '站点管理', lang: 'manage.nav.site', com: StationList, args: { stationType: 0 } },
        { code: 's9', name: '通信管理', lang: 'manage.nav.comm', com: DeviceList, args: { start: 24, end: 24000 } },
        { code: 's1', name: '配电柜管理', lang: 'manage.nav.ggd', com: PowerList, args: {} },
        { code: 's2', name: '单灯管理', lang: 'manage.nav.lamp', com: LightList, args: {} },
        // {code: 's3', name: '光控属性', lang:'',com: LuxList, args: {}},
        // {code: 's4', name: '光控策略', lang:'',com: LuxRuleItem, args: {}},
        { code: 's5', name: '时间表管理', lang: 'manage.nav.schedule', com: TimeIndex, args: {} },
        { code: 's10', name: '告警管理', lang: 'manage.nav.alarm', com: AlarmIndex, args: {} },
        // { code: 's10', name: '报警规则设置', lang:'',com: AlarmRule, args: {} },
        // { code: 's11', name: '报警等级配置', lang:'',com: AlarmLevel, args: {} },
        { code: 's12', name: '首页地图配置', lang: 'manage.nav.home.map', com: setMap, args: {} },
        // { code: 's13', name: '外接账号管理', lang:'',com: ConfigAccountIndex, args: {tab: 's4'} },
        // { code: 's14', name: '通用管理', lang:'',com: currencyIndex, args: {tab: 's1'} },

      ],
    }
  },
  computed: {
    ...mapGetters('auth', ['showLang']),
  },

  watch: {
  },
  mounted: function () {
  },
  destroyed: function () {
  },
  methods: {
    switchTab: function (type) {
      this.curTab = type;
    },
  }
}
</script>
<style scoped>
.station-config-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  /* border: solid 1px red; */
  background-color: white;
  /* padding-right: 40px; */
}
.station-config-tabs {
  height: 48px;
  flex: none;
  display: flex;
}
.station-config-tab {
  padding: 2px 10px;
  cursor: pointer;
  background-color: #fff;
  color: #324252;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 48px;
}
.tab-active {
  color: #021629;
  font-weight: bold;
}
.tab-active::after {
  content: "";
  width: calc(100%);
  height: 1px;
  display: block;
  margin: -5px auto 0;
  border-bottom: 2px solid #3880fc;
}
.station-config-tabcontent {
  height: 500px;
  flex: auto;
  /* border: solid 1px red; */
  overflow: hidden;
  padding-top: 5px;
}
/* .box-body{
  height: 0;
  flex: auto;
  margin-top: 5px;
  border: solid 1px red;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: relative;
}
.box-body::-webkit-scrollbar {
  display: none;
} */
</style>