<template>
<div class="info-container" ref="table2">
  <div class="info-header">
    <div class="info-header-title">{{showLang('com.data.real.time')}}</div>
  </div>
  <div class="info-body">
    <StationList :tabHeight="tabHeight" />
  </div>
</div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import StationList from '../powerbox/Station'
import { setCookie, getCookie } from '@/plugins/cookie'
export default {
  name: 'BaseGroupInfo',
  components: {
    StationList,
  },
  props: {
  },
  data() {
    return {
      isShowAnalysis: true,
      tabHeight: 600,
    }
  },
  computed: {
    ...mapGetters('auth', ['showLang']),
    ...mapGetters('group', ['groupPathName']),
    ...mapState("cmd", ['windowOnResize', "commands", "cmdResultRefresh"]),
    ...mapState('common', ['weeks', 'productCodes', 'topDevices', 'deviceTypes']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
  },
  watch: {
    selectedNode() {
    },
    cmdResultRefresh() {
    },
    windowOnResize(){
      // this.setTableHeight();
    },
  },
  mounted: function () {
    // setTimeout(this.setTableHeight, 100);
    if (getCookie('isShowAnalysis') == '' || getCookie('isShowAnalysis') == 'true') {
      this.isShowAnalysis = true;
    } else {
      this.isShowAnalysis = false;
    }
  },
  destroyed: function () {
  },
  methods: {
    setTableHeight: function(){
      this.tabHeight = this.$refs.table2.clientHeight - 200;
      // console.log('widow resize', this.$refs.table2.clientHeight, this.tabHeight)
    },
    showAnalysisBut: function(){
      this.isShowAnalysis = !this.isShowAnalysis;
      setCookie('isShowAnalysis', this.isShowAnalysis);
    },
  }
}
</script>
<style scoped>
.info-container{
  /* flex: none; */
  /* border: solid 1px red; */
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  background-color: white;
  height: 600px;
  flex: auto;
}
.info-header{
  border-bottom: solid 1px #DEE5ED;
  width: 100%;
  height: 38px;
  flex: none;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.info-header-title{
  width: 300px;
  flex: auto;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #021629;
}
.info-body{
  flex: none;
  display: flex;
  flex-direction: column;
}
.info-body-count{
  flex: none;
  display: flex;
  margin: 40px 0;
}
</style>