<template>
  <Modal v-model="showModal" :mask-closable="false" width="700px">
    <div slot="header">配置参数信息</div>
    <Form ref="form" :model="form" :rules="rules" :label-width="110">
      <FormItem v-if="['updatePowerArgs', 'updateCurrentRated', 'updateCurrentRate', 'updateVoltageArgs'].indexOf(item.code) != -1" label="应用范围" style="margin-right: 15px">
        <CheckboxGroup v-model="form.abcRange">
          <Checkbox label="A相"></Checkbox>
          <Checkbox label="B相"></Checkbox>
          <Checkbox label="C相"></Checkbox>
        </CheckboxGroup>
      </FormItem>
      <FormItem v-if="['updatePowerArgs'].indexOf(item.code) != -1" label="额定容量" style="margin-right: 15px">
        <Input type="number" number v-model="form.pr"><span slot="append">W</span></Input>
      </FormItem>
      <FormItem v-if="['updatePowerArgs'].indexOf(item.code) != -1" label="过载阈值" style="margin-right: 15px">
        <Input type="number" number v-model="form.ph"><span slot="append">%</span></Input>
      </FormItem>
      <FormItem v-if="['updatePowerArgs'].indexOf(item.code) != -1" label="功率因数阈值" style="margin-right: 15px">
        <Input type="number" number v-model="form.pf"></Input>
      </FormItem>
      <FormItem v-if="['updateVoltageArgs'].indexOf(item.code) != -1" label="额定电压" style="margin-right: 15px">
        <Input type="number" number v-model="form.ur"><span slot="append">A</span></Input>
      </FormItem>
      <FormItem v-if="['updateVoltageArgs'].indexOf(item.code) != -1" label="下限值" style="margin-right: 15px">
        <Input type="number" number v-model="form.ul"><span slot="append">%</span></Input>
      </FormItem>
      <FormItem v-if="['updateVoltageArgs'].indexOf(item.code) != -1" label="上限值" style="margin-right: 15px">
        <Input type="number" number v-model="form.uh"><span slot="append">%</span></Input>
      </FormItem>
      <FormItem v-if="['updateCurrentRated'].indexOf(item.code) != -1" label="电流倍率" style="margin-right: 15px">
        <div class="flex-row">
          <Input type="number" number v-model="form.ct"><span slot="append">倍</span></Input>
        </div>
      </FormItem>
      <FormItem v-if="['updateCurrentRate'].indexOf(item.code) != -1" label="配置方式" style="margin-right: 15px">
        <i-switch v-model="form.fixed" size="large" true-color="green" false-color="blue" style="margin: 0 10px;width:56px">
          <span slot="open">固定</span>
          <span slot="close">实时</span>
        </i-switch>
        选择“固定”设置固定额定值，选择“实时”设置额定值为当前电流值
      </FormItem>
      <FormItem v-if="['updateCurrentRate'].indexOf(item.code) != -1 && form.fixed" label="额定电流值" style="margin-right: 15px">
        <Input type="number" number v-model="form.cr"><span slot="append">A</span></Input>
      </FormItem>
      <FormItem v-if="['updateCurrentRate'].indexOf(item.code) != -1" label="下限值" style="margin-right: 15px">
        <Input type="number" number v-model="form.cl"><span slot="append">%</span></Input>
      </FormItem>
      <FormItem v-if="['updateCurrentRate'].indexOf(item.code) != -1" label="上限值" style="margin-right: 15px">
        <Input type="number" number v-model="form.ch"><span slot="append">%</span></Input>
      </FormItem>
      <FormItem v-if="['updateLeakageArgs'].indexOf(item.code) != -1" label="漏电倍率" style="margin-right: 15px">
        <Input type="number" number v-model="form.ct"><span slot="append">倍</span></Input>
      </FormItem>
      <FormItem v-if="['updateLeakageArgs'].indexOf(item.code) != -1" label="一级阈值" style="margin-right: 15px">
        <Input type="number" number v-model="form.ll"><span slot="append">mA</span></Input>
      </FormItem>
      <FormItem v-if="['updateLeakageArgs'].indexOf(item.code) != -1" label="二级阈值" style="margin-right: 15px">
        <Input type="number" number v-model="form.lh"><span slot="append">mA</span></Input>
      </FormItem>
      <FormItem v-if="['updateLeakageArgs'].indexOf(item.code) != -1" label="三级阈值" style="margin-right: 15px">
        <Input type="number" number v-model="form.lx"><span slot="append">mA</span></Input>
      </FormItem>
      <FormItem v-if="['updateSteelArgs'].indexOf(item.code) != -1" label="防盗阈值" style="margin-right: 15px">
        <Input type="number" number v-model="form.sh"><span slot="append">秒</span></Input>
      </FormItem>
    </Form>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">取消</Button>
      <Button type="primary" :loading="loading" @click="ok">保存</Button>
    </div>
    <!-- <ModalSelectGroup v-model="showSelectModal" @newGroupSelected="newGroupSelected" /> -->
  </Modal>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'ModalArgEdit',
  components:{
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default(){ return {};}
    },
  },
  data () {
    return {
      showModal: this.value,
      loading: false,
      isAdd: false,
      form: {
        abcRange: [ "A相", "B相", "C相" ],
        pr: 220,
        ph: 80,
        pf: 0.8,
        fixed: false,
        ct: 100,
        cr: 1,
        cl: 80,
        ch: 120,
        ll: 100,
        lh: 1000,
        lx: 2000,
        sh: 30,
        ur: 220,
        ul: 80,
        uh: 120,
      },
      rules: {
        // name: { required: true, type: 'string', max: 20, message: '名称不能为空且长度必须少于20个字符', trigger: 'blur' },
        // topCode: { required: true, type: 'string', max: 20, message: '编码不能为空且长度必须少于20个字符', trigger: 'blur' },
        // location: { required: false, type: 'string', max: 50, message: '安装位置长度必须少于50个字符', trigger: 'blur' },
        // monitorCode: { required: true, type: 'string', max: 20, message: '编码不能为空且长度必须少于20个字符', trigger: 'blur' },
      },
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if(newVal){
        // console.log('modal args edit mounted', this.item)
        // console.log('modal level edit mounted', this.form.content, this.item.data.content)
      }
    },
    showModal(val){
      this.$emit('showChanged', val);
    },
    // 'form.stationId'(){
    //   this.form.paramId = 0;
    // },
  },
  computed: {
    ...mapState('common', ['alarmTypes', 'alarmGroupList']),
    ...mapState('common', ['currentChannelCount', 'leakageChannelCount', 'steelChannelCount', 'weeks', 'deviceTypes', 'productCodes', 'powerBoxTypes']),
  },
  mounted: function(){
    // this.getProjects();
    // console.log('modal level edit mounted', this.alarmTypes, this.alarmGroupList)
  },
  methods: {
    ok: async function () {
      if(['updatePowerArgs', 'updateCurrentRated', 'updateCurrentRate'].indexOf(this.item.code) != -1){
        if(this.form.abcRange.length == 0){
          this.$Message.warning('请至少选择一个相位');
          return;
        }
      }
      let args = {};
      if(this.item.code == 'updatePowerArgs'){
        if(this.form.pr <= 0){
          this.$Message.warning('请设置额定容量');
          return;
        }
        if(this.form.ph <= 0){
          this.$Message.warning('请设置过载阈值');
          return;
        }
        if(this.form.pf <= 0){
          this.$Message.warning('请设置功率因数阈值');
          return;
        }
        args = {code: this.item.code, range: this.form.abcRange, pr: this.form.pr, ph: this.form.ph, pf: this.form.pf};
      }
      if(this.item.code == 'updateVoltageArgs'){
        if(this.form.ur <= 0){
          this.$Message.warning('请设置电压额定值');
          return;
        }
        if(this.form.ul <= 0){
          this.$Message.warning('请设置电压下限值');
          return;
        }
        if(this.form.uh <= 0){
          this.$Message.warning('请设置电压上限值');
          return;
        }
        args = {code: this.item.code, range: this.form.abcRange, ur: this.form.ur, ul: this.form.ul, uh: this.form.uh};
      }
      if(this.item.code == 'updateCurrentRated'){
        if(this.form.ct <= 0 || this.form.ct > 65535){
          this.$Message.warning('电流倍率在 1 - 65535 之间');
          return;
        }
        args = {code: this.item.code, range: this.form.abcRange, ct: this.form.ct};
      }
      if(this.item.code == 'updateCurrentRate'){
        if(this.form.fixed && this.form.cr <= 0){
          this.$Message.warning('请设置电流额定值');
          return;
        }
        if(this.form.cl <= 0){
          this.$Message.warning('请设置电流下限值');
          return;
        }
        if(this.form.ch <= 0){
          this.$Message.warning('请设置电流上限值');
          return;
        }
        args = {code: this.item.code, range: this.form.abcRange, fixed: this.form.fixed, cr: this.form.cr, cl: this.form.cl, ch: this.form.ch};
      }
      if(this.item.code == 'updateLeakageArgs'){
        if(this.form.ct <= 0 || this.form.ct > 65535){
          this.$Message.warning('漏电电流倍率在 1 - 65535 之间');
          return;
        }
        if(this.form.ll <= 0){
          this.$Message.warning('请设置一级漏电阈值');
          return;
        }
        if(this.form.lh < this.form.ll){
          this.$Message.warning('二级漏电阈值必须大于一级漏电阈值');
          return;
        }
        if(this.form.lx < this.form.lh){
          this.$Message.warning('三级漏电阈值必须大于二级漏电阈值');
          return;
        }
        args = {code: this.item.code, ct: this.form.ct, ll: this.form.ll, lh: this.form.lh, lx: this.form.lx};
      }
      if(this.item.code == 'updateSteelArgs'){
        if(this.form.sh <= 0){
          this.$Message.warning('请设置防盗时间阈值');
          return;
        }
        args = {code: this.item.code, sh: this.form.sh};
      }
      this.$emit('saved', args);
      this.showModal = false;
    },
    cancel () {
      this.showModal = false;
    },
  }
}
</script>
<style scoped>
.form-item-group{
  border: solid 1px rgba(129, 124, 124, 0.377);
  border-radius: 12px;
  margin-bottom: 15px;
}
.group-header{
  /* border: solid 1px blue; */
  background-color: rgba(221, 216, 216, 0.808);
  margin-bottom: 15px;
  height: 35px;
  border-radius: 12px 12px 0 0;
  line-height: 35px;
  font-weight: bold;
  padding: 0 10px;
}
  .demo-drawer-footer{
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    border-top: 1px solid #e8e8e8;
    padding: 10px 16px;
    text-align: right;
    background: #fff;
  }
  .form{
    height: calc(100% - 54px);
    overflow: auto;
  }
  .readonly{
    background: #eee
  }
</style>