<template>
  <div class="map">
    <div v-if="funCodes('hma')">{{showLang('com.op.add.picture')}}</div>
    <Upload v-if="funCodes('hma')" ref="upload" :headers="headers" :show-upload-list="false" :default-file-list="defaultList" :on-success="handleSuccess" :format="['jpg','jpeg','png']" :max-size="20480" :on-format-error="handleFormatError" :on-exceeded-size="handleMaxSize" multiple type="drag" :action="`//${this.domains.trans}/group/map`" style="display: inline-block;width:58px;">
      <div style="width: 58px;height:58px;line-height: 58px;">
        <Icon type="ios-camera" size="20"></Icon>
      </div>
    </Upload>
    <div class="station-data-area" ref="table">
      <template>
        <u-table :data="list" :border="false" :height="tabHeight" style="width: 100%">
          <template slot="empty">
            {{showLang('com.tips.no.query.records')}}
          </template>
          <u-table-column prop="img" :label="showLang('com.op.picture')">
            <template slot-scope="scope">
              <div class="listImg">
                <img :src="scope.row.img" alt="">
              </div>
            </template>
          </u-table-column>
          <u-table-column prop="groupName" :label="showLang('com.group')" width="180">
          </u-table-column>
          <u-table-column prop="updateTime" :label="showLang('com.date.time')" width="180">
          </u-table-column>
          <u-table-column :label="showLang('com.right.nav.operate')">
            <template slot-scope="scope">
              <AuthButton opCode='hmd' size="default" type="error" style="margin-right: 5px" @click="delImg(scope.row)">{{showLang('com.op.del')}}</AuthButton>
            </template>
          </u-table-column>
        </u-table>
      </template>
    </div>
    <Modal v-model="choiceGrouping" :title="showLang('com.right.nav.operate')" @on-ok="groupingOk" @on-cancel="groupingCancel">
      <i-switch size="large" v-model="isBaseMap">
        <span slot="open">{{showLang('com.op.underlay')}}</span>
        <span slot="close">{{showLang('com.group')}}</span>
      </i-switch>
      <Select :placeholder="showLang('save.select')" v-model="groupingId" style="width:200px;margin-left: 20px;">
        <Option v-for="item in groups" :value="item.id" :key="item.id">{{ item.name }}</Option>
      </Select>
      <p v-if="isBaseMap">{{showLang('com.tisp.underlay')}}</p>
    </Modal>

  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'setMap',
  components: {
  },
  props: {
  },
  data() {
    return {
      list: [],
      defaultList: [],
      groupingArr: [],
      choiceGrouping: false,
      groupingId: "",
      isBaseMap: false,
      showImgId: "",
      tabHeight: 300,
      headers: {
        auth: localStorage.getItem('token'),
      },
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes','showLang']),
    ...mapState('group', ['selectedDevices', 'selectedNode', 'groups']),
    ...mapState("auth", ["token", "user", "customerId", "appTypes"]),
  },
  watch: {

  },
  mounted: function () {
    // this.groupingOk();
    this.getImg();
    setTimeout(() => {
      this.tabHeight = this.$refs.table.clientHeight;
    }, 200);
  },
  methods: {
    delImg(el) {
      this.$Modal.confirm({
        title: this.showLang('com.op.prompt'),
        content: this.showLang('com.tips.ok.del.picture'),
        onOk: async () => {
          this.$axios.delete(`//${this.domains.trans}/group/map/${el.id}`).then(res => {
            if (res.code == 0) {
              this.$Message.info(this.showLang('com.tips.del.success'));
              this.getImg();
            }
          });
        }
      });

    },
    groupingOk() {
      let add = 0;
      if (this.isBaseMap) {
        add = this.list.filter(p => p.isBase == true).length > 0 ? 1 : 0
      }
      if (this.groupingId == "") {
        this.$Message.warning(this.showLang('save.picture.group'));
        return false;
      }
      let ajax = {
        "isBase": this.isBaseMap,
        "img": this.showImgId,
        "groupId": this.groupingId
      }
      this.$axios.put(`//${this.domains.trans}/group/map/${add}`, ajax).then(res => {
        if (res.code == 0) {
          this.$Message.info(this.showLang('com.ins.success'));
          this.getImg();
        }
      });
    },
    groupingCancel() {},
    getImg() {
      this.$axios.get(`//${this.domains.trans}/group/map`).then(res => {
        if (res.code == 0) {
          res.data.forEach(el => {
            this.groups.forEach(item => {
              if (el.groupId == item.id) {
                el.groupName = el.isBase ? item.name + "（"+this.showLang('com.op.underlay')+"）" : item.name;
              }
            });
            el.img = `//${this.domains.trans}/group/map/${el.img}?auth=${this.token}`
          });
          this.list = res.data;
        }
      });
    },
    handleSuccess(res) {
      if (res.code != 0) {
        // this.$Message.error(res.data);
        return;
      }
      this.showImgId = JSON.parse(new Buffer(res.data, 'base64').toString());
      this.choiceGrouping = true;
    },
    handleMaxSize(file) {
      this.$Notice.warning({
        title: 'Exceeding file size limit',
        desc: 'File  ' + file.name + ' is too large, no more than 2M.'
      });
    },
    handleFormatError(file) {
      this.$Notice.warning({
        title: 'The file format is incorrect',
        desc: 'File format of ' + file.name + ' is incorrect, please select jpg or png.'
      });
    },
  }
}
</script>
<style scoped>
.listImg {
  width: 220px;
  height: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
  float: left;
  margin: 5px;
}
.listImg img {
  max-height: 220px;
  max-width: 220px;
}
.station-data-area {
  height: calc(100% - 90px);
  flex: auto;
}
.map {
  height: 100%;
  flex: auto;
}
</style>