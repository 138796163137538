<template>
  <Modal v-model="showModal" :mask-closable="false" width="800px" :title="`${item.data.name}灯杆图片设置`">
    <div class="info-details">
      <div class="info-img-list" v-for="(el,index) in pictureList" :key="index">
        <img :src="img.setTime" class="info-delImg"
          style="margin-right: 83px; background-color: #1c1b1bb5;border-radius: 3px;" @click="downImg()" />
        <img :src="img.light.info.enlargeImg" alt="" class="info-delImg" style="margin-right: 43px;"
          @click="enlarge(el)" />
        <img :src="img.light.info.deleteImg" alt="" class="info-delImg" @click="delImg(el)" />
        <img :src="`//${domains.trans}/station/config/ViewImage?id=${el}&auth=${token}`" alt="" class="info-img" />
      </div>
      <Upload ref="upload" :headers="headers" :data="headers" :show-upload-list="false" :on-success="handleSuccess"
        :format="['jpg','jpeg','png']" :max-size="20480" :on-format-error="handleFormatError"
        :on-exceeded-size="handleMaxSize" multiple type="drag"
        :action="`//${this.domains.trans}/station/config/UploadPoleImage`" style="border:none">
        <div class="info-img-list">
          <img :src="img.light.info.addImg" alt="" class="info-img" style="cursor: pointer;">
        </div>
      </Upload>
    </div>
    <Modal v-model="isShowImg" :title="showLang('com.img.amplify')" width="1000">
      <div style="text-align: center;" v-if="isShowImgUrl"> <img
          :src="`//${domains.trans}/station/config/ViewImage?id=${isShowImgUrl}&auth=${token}`" alt=""
          :style="{width:'100%', verticalAlign: 'middle', transform: `rotate(${rotateAngle}deg)`}"></div>
      <div slot="footer">
        <Button type="info" style="margin-right: 8px" @click="leftRotate">{{showLang('com.img.Left.Rotation')}}</Button>
        <Button type="info" style="margin-right: 8px"
          @click="rightRotate">{{showLang('com.img.Right.Rotation')}}</Button>
        <Button style="margin-right: 8px" type="primary" @click="downImg()">{{showLang('com.op.download')}}</Button>
        <Button style="margin-right: 8px" @click="cancel">{{showLang('com.op.close')}}</Button>
      </div>
    </Modal>
  </Modal>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'ModalLampImportNew',
  components: {
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default() { return {}; }
    },
    stations: {
      type: Array,
      default() { return []; }
    },
  },
  data() {
    return {
      showModal: this.value,
      // showSelectModal: false,
      loading: false,
      isAdd: false,
      isShowImg: false,
      rotateAngle: 0,
      pictureList: [],
      isShowImgUrl: '',
      headers: {
        poleId: "",
        auth: localStorage.getItem('token'),
      }
    }
  },
  computed: {
    ...mapState('auth', ['appType']),
    ...mapGetters('auth', ['showLang']),
    ...mapState('cmd', ['windowOnResize']),
    ...mapState('auth', ['token']),
    ...mapState('common', ['powerBoxAlarmTypes', 'productCodes', 'connectTypes', 'deviceTypes', 'newLightTypes', 'smallTypes']),
    ...mapState('group', ['selectedDevices', 'selectedNode', 'selectedGroups']),
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if (newVal) {
        this.isAdd = this.item.isAdd;
        this.headers.poleId = this.item.data.id;
        this.setPicture();
      }
    },
    showModal(val) {
      this.$emit('showChanged', val);
    },
  },
  mounted: function () {

  },
  methods: {
    setPicture() {
      this.$axios.post(`//${this.domains.trans}/station/config/QueryPoleImages`, { poleId: this.item.data.id }).then(res => {
        if (res.code == 0) {
          this.$set(this, 'pictureList', res.data);
        }
      });
    },
    handleMaxSize(file) {
      this.$Notice.warning({
        title: this.showLang('com.img.tisp.size'),
        desc: this.showLang('com.img.tisp.file.size', file.name) + '20M'
      });
    },
    handleFormatError(file) {
      this.$Notice.warning({
        title: this.showLang('com.img.tisp.format'),
        desc: this.showLang('com.img.tisp.file.format', file.name)
      });
    },
    downImg() {//下载
      if (this.isShowImgUrl == '') { return false }
      let url = `//${this.domains.trans}/station/config/ViewImage?id=${this.isShowImgUrl}&auth=${this.token}`;
      this.$axios.get(url, { responseType: "blob" }).then(response => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(response);
        link.download = this.showLang('com.img.device') + '.png';
        link.click();
        URL.revokeObjectURL(link.href);
      }).catch(console.error);
    },
    handleSuccess(res) {
      if (res.code == 0) {
        this.setPicture()
      }
    },
    leftRotate: function () {
      this.rotateAngle -= 90;
    },
    rightRotate: function () {
      this.rotateAngle += 90;
    },
    enlarge(el) {//放大
      this.isShowImg = true;
      this.isShowImgUrl = el;
    },
    ok: async function () {
      this.showModal = false;
      this.$emit('saved', {});
    },
    cancel() {
      this.isShowImg = false;
    },
    delImg: async function (id) {
      this.$Modal.confirm({
        title: this.showLang('com.op.prompt'), content: this.showLang('com.tips.ok.del.picture'), onOk: async () => {
          let res = await this.$axios.post(`//${this.domains.trans}/station/config/DeleteImageById`, { imageId: id });
          if (res.code == 0) {
            this.$Message.info(this.showLang('com.tips.del.success'));
            this.setPicture();
          }
        }
      });
    },
  }
}
</script>
<style scoped>
.info-details {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 15px 0 25px;
}
.info-img-list {
  width: 220px;
  height: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
  float: left;
  background: #e3e9ef;
  border-radius: 12px;
  margin-right: 20px;
  position: relative;
  margin-top: 10px;
  margin-bottom: 8px;
}
.info-img {
  max-width: 220px;
  max-height: 220px;
  vertical-align: middle;
}
.info-delImg {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  width: 30px;
  height: 30px;
}
.info-details >>> .ivu-upload-drag {
  border: none;
}
</style>