<template>
<div>
  <table border="0" cellspacing="0" cellpadding="0" v-if="alarmsData && alarmsData.length > 0">
    <!-- <tr>
      <td></td>
      <td>报警站点</td>
      <td>报警设备</td>
      <td>报警类型</td>
      <td>报警内容</td>
      <td>报警时间</td>
    </tr> -->
    <tr v-for="(item, idx) in alarmsData" :key="idx">
      <td style="width: 10px;">
        <div class="block"></div>
      </td>
      <td style="width: 170px; text-align: left" @click="jumpDetailsDve(item)">
        <template v-if="getItemNameLength(item) <= 10">{{item.stationName}}</template>
        <Tooltip v-else placement="top-start"
          style="width: 150px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
          {{item.stationName}}
          <div slot="content" style="white-space: normal">
            {{item.stationName}}
          </div>
        </Tooltip>
      </td>
      <td style="width: 100px; text-align: left" @click="showAlarmHisData(item)">
        <template v-if="getItemNameLength(item) <= 10">{{item.paramName}}</template>
        <Tooltip v-else placement="top-start"
          style="width: 100px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;margin-top: 5px;">
          {{item.paramName}}
          <div slot="content" style="white-space: normal">
            {{item.paramName}}
          </div>
        </Tooltip>
      </td>
      <td style="width: 120px; text-align: left;color:#E84F4D;" @click="showAlarmHisData(item)">
        <template v-if="item.name && item.name.length <= 6">{{item.name}}</template>
        <Tooltip v-else placement="top-start"
          style="width: 100px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
          {{item.name}}
          <div slot="content" style="white-space: normal">
            {{item.name}}
          </div>
        </Tooltip>
      </td>
      <td style="width: 470px; text-align: left" @click="showAlarmHisData(item)">
        <template v-if="!item.extra || item.extra.length <= 30"><span
            :style="{color: item.isConfirm ? 'blue' : 'red'}">[{{item.isConfirm ? '已确认' : '未确认'}}]</span>
          {{item.extra}}</template>
        <Tooltip v-else placement="top-start" class="tl"
          style="width: 450px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
          <span :style="{color: item.isConfirm ? 'blue' : 'red'}">[{{item.isConfirm ? '已确认' : '未确认'}}]</span>
          {{item.extra}}
          <div slot="content" style="white-space: normal">
            {{item.extra}}
          </div>
        </Tooltip>
      </td>
      <td style="width: 170px;" @click="showAlarmHisData(item)">{{item.startTime}}</td>
    </tr>
  </table>
  <div v-else>
    <div class="lux-empty">
      <img :src="img.lux.empty" />
      <div class="empty-text">{{showLang('com.data.no.alarm')}}</div>
    </div>
  </div>
  <ModalAlarmData v-model="showAlarmDataModal" :item="editItem" />
</div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import ModalAlarmData from '@/views/light/road/monitor/station/alarm/report/ModalAlarmData'
export default {
  name: 'ReportSimple',
  components: {
    ModalAlarmData,
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      showModal: this.value,
      curtab: 'power',
      editItem: {},
      showAlarmDataModal: false,
      isShowPoliceBox: true,
      alarms: [],
      total: 0,
      page: {
        currentPage: 1,
        pageSize: 20,
      },
      pageSizes: [20, 100, 500, 1000, 5000],
      alarmsData: [],
      timeout: null,
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
    },
    selectedNode() {
      this.clockAjax(this.page.currentPage = 1);
    },
    showModal(val) {
      this.$emit('showChanged', val);
    },
  },
  destroyed: function () {
  },
  computed: {
    ...mapGetters('auth', ['showLang','funCodes']),
    ...mapState('group', ['selectedNode', 'groupLoaded', 'selectedDevices', 'stationAlarmChanged']),
    ...mapState('common', ['alarmTypes', 'productCodes']),
  },
  mounted: function () {
    this.clockAjax();
  },
  methods: {
    showAlarmHisData: function (item) {
      this.editItem = item;
      this.showAlarmDataModal = true;
    },
    getItemNameLength: function (item) {
      let len = 0;
      if (item.stationName) {
        len += item.stationName.length;
      }
      if (item.pName) {
        len += item.pName.length;
      }
      return len;
    },
    clockAjax() {
      let groupId = 0, stationId = 0;
      if (this.selectedNode.type == this.productCodes.station) {
        stationId = this.selectedNode.id;
      } else {
        groupId = this.selectedNode.id;
      }
      this.$axios.post(`//${this.domains.trans}/station/alarm/QueryReportSimple`, { groupId, stationId }).then(res => {
        if (res.code == 0) {
          this.$set(this, 'alarmsData', res.data);
        }
      });
    },
    jumpDetailsDve(params) {
      this.$store.commit('group/selectNodeById', { nodeId: params.stationId, type: this.productCodes.station });
      this.$store.commit('auth/switchStationTabName', this.curtab);
    },
  }
}
</script>
<style scoped>
.lux-empty {
  width: 100%;
  height: 100%;
  position: relative;
}
.empty-text {
  /* border: solid 1px red; */
  position: absolute;
  top: 280px;
  left: 50%;
  width: 100px;
  height: 26px;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #324252;
  line-height: 26px;
  text-align: center;
  margin-left: -50px;
}
.lux-empty img {
  position: absolute;
  top: 30px;
  left: 50%;
  width: 340px;
  height: 290px;
  margin-left: -170px;
}
.modal-class {
  border: solid 1px red;
}
.tab-item {
  flex: none;
  cursor: pointer;
  background: #ebf0f9;
  border-radius: 19px;
  padding: 0 23px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #68737d;
  text-align: center;
  margin-right: 30px;
}
.police-top .tab-item img {
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
}
.tab-active {
  background-color: #e84f4d;
  color: #ffffff;
}
.police-box {
  width: 460px;
  height: 416px;
  background: #edf2f9;
  border-radius: 12px;
  position: fixed;
  right: 230px;
  bottom: 85px;
  z-index: 500;
}
.police-top {
  border-radius: 6px 6px 0px 0px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  margin: -15px;
  line-height: 38px;
  display: flex;
  padding: 12px 15px;
}
/* .police-top img {
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
} */
.police-list {
  width: 440px;
  height: 335px;
  background: #ffffff;
  box-shadow: 0px 12px 30px 0px rgba(31, 106, 237, 0.2);
  border-radius: 12px;
  margin: 10px auto 0;
}
.police-but {
  width: 78px;
  height: 28px;
  border: 1px solid #3880fc;
  border-radius: 4px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #3880fc;
  line-height: 22px;
  text-align: center;
  float: right;
  margin: 10px 15px 0 0;
  cursor: pointer;
}
.police-ul {
  height: 280px;
  list-style-type: square;
  color: #c30f0b;
  padding: 0 15px 0 18px;
  overflow-y: auto;
}
.police-li {
  display: flex;
  justify-content: space-between;
  height: 35px;
  line-height: 35px;
}
.block {
  /* display: inline-block; */
  width: 6px;
  height: 6px;
  background: #db0e30;
  margin-right: 10px;
  /* vertical-align: middle; */
}
.analyse-table {
  width: 100%;
  height: 500px;
  overflow-x: hidden;
  overflow-y: auto;
}
.analyse-table table td {
  text-align: center;
  box-sizing: border-box;
  font-size: 15px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #324252;
  height: 36px;
  cursor: pointer;
  background: #f4f7fd;
  padding-left: 5px;
}

.analyse-table table tr:last-child td {
  border-bottom: none;
}
.analyse-table table tr:nth-of-type(odd) td {
  background: #fff;
}
.analyse-table table tr td:last-child {
  border-right: none;
}
.police-con {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #324252;
}
.police-con.police-title {
  max-width: 225px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
}
</style>