<template>
<div class="light-container">
  <div class="power-areas">
    <CommonTree :args="{base: 'powerbox'}" />
  </div>
  <CommonContent />
</div>
</template>
<script>
// import Vue from 'vue';
import { mapState } from 'vuex'
import CommonTree from '../tree/Index'
import CommonContent from './Content'
// import { sessionData } from '@/plugins/cookie'
// Vue.prototype.$sessionData = { sessionData }
export default {
  name: 'Home',
  components: {
    CommonTree,
    CommonContent,
  },
  data() {
    return {
      // isType: "root",
      // editItem: {},
      // showAlarmDataModal: false,
      // isShowPoliceBox: false,
      // policeTypeData: [],
      showDrawer: false,
    }
  },
  watch: {
    // selectedNode() {
    //   this.isType = this.selectedNode.type;
    // },
  },

  // destroyed: function () {
  // },
  computed: {
    // ...mapState('group', ['selectedNode', 'groupLoaded', 'selectedDevices']),
    // ...mapState('common', ['alarmTypes', 'productCodes']),
    ...mapState('auth', ['showStationMenu']),
  },
  mounted: function () {
    // let isShow = this.$sessionData.sessionData('get', "policeBox", "");
    // if (isShow == true || isShow == 'true') {
    //   this.isShowPoliceBox = true;
    // } else {
    //   this.isShowPoliceBox = false;
    // }
    // this.isType = this.selectedNode.type;
  },
  destroyed: function () {
  },
  methods: {
    // policeClose() {
    //   this.isShowPoliceBox = !this.isShowPoliceBox
    //   this.$sessionData.sessionData('set', "policeBox", this.isShowPoliceBox);
    // },
    // switchMenu: function (menu) {
    //   this.$store.commit('auth/setStationMenu', menu);
    // },
    // showDeviceDetail: function (dev) {
    //   this.$refs.tree.selectNodeByData(dev);
    // },
  }

}
</script>
<style scoped>
.power-areas {
  /* border: solid 1px red; */
  width: 300px;
  margin: 0;
  flex: none;
  background-color: #001f37;
  display: flex;
  flex-direction: column;
}
.light-container {
  background: #e3e9ef;
  display: flex;
  width: 100%;
  align-items: stretch;
  /* border: solid 1px red; */
  position: relative;
}
</style>