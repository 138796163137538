<template>
  <div :class="[showMore?'control-table hideShita':'control-table element']">
    <div class="info-header">
      <div class="info-header-title">{{showLang('com.lighting.common.operate')}}</div>
      <img :src="!showMore?`${img.light.road.single.header.down}`:`${img.light.road.single.header.upward}`" alt=""
        @click="switchMenu">
    </div>
    <div class="control-area">
      <template v-for="item in canDoCmds">
        <div class="control-item" v-if="funCodes(item.opcode)" :key="item.code" @click="docmd(item)">
          <img :src="item.img" />
          {{item.name}}
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
export default {
  name: 'LightControlMenu',
  components: {
  },
  props: {
  },
  data() {
    return {
      showMore: false,
      items: []
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes', 'showLang']),
    ...mapState('auth', ['config']),
    ...mapState('common', ['weeks', 'productCodes', 'powerBoxTypes']),
    ...mapState("cmd", ["commands", "cmdResultRefresh"]),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
    canDoCmds: function () {
      let cmds = [];
      for (let cmd of this.items) {
        if (this.config.lightShowCommands.indexOf(cmd.code) == -1) continue;
        if (this.funCodes(cmd.opcode)) cmds.push(cmd);
      }
      return cmds;
    }
  },
  mounted: function () {
    this.items = [
      { needArgs: false, needPswd: false, code: 'forceRead', opcode: 'dcs', name: this.showLang('com.but.survey'), img: this.img.light.road.single.lightCtrl.forceRead },
      { needArgs: true, needPswd: true, code: 'openSingle', opcode: 'dco', name: this.showLang('com.but.open.lamp'), img: this.img.light.road.single.lightCtrl.open },
      { needArgs: true, needPswd: true, code: 'closeSingle', opcode: 'dco', name: this.showLang('com.but.close.lamp'), img: this.img.light.road.single.lightCtrl.close },
      { needArgs: true, needPswd: true, code: 'handSingle', opcode: 'dco', name: this.showLang('com.but.dimming'), img: this.img.light.road.single.lightCtrl.adjust },
      { needArgs: false, needPswd: false, code: 'getclock', opcode: 'dcs', name: this.showLang('com.but.get.QueryClock'), img: this.img.light.road.single.lightCtrl.readClock },
      { needArgs: false, needPswd: true, code: 'setclock', opcode: 'dcc', name: this.showLang('com.but.get.Calibrate'), img: this.img.light.road.single.lightCtrl.writeClock },
      { needArgs: false, needPswd: false, code: 'getDayPlan1', opcode: 'dcs', name: this.showLang('com.but.get.timing'), img: this.img.light.road.single.lightCtrl.readTime1 },
      { needArgs: true, needPswd: true, code: 'setDayPlan1', opcode: 'dcc', name: this.showLang('com.but.set.timing'), img: this.img.light.road.single.lightCtrl.writeTime1 },
      { needArgs: false, needPswd: false, code: 'getDayPlan2', opcode: 'dcs', name: this.showLang('com.but.get.ontime'), img: this.img.light.road.single.lightCtrl.readTime2 },
      { needArgs: true, needPswd: true, code: 'setDayPlan2', opcode: 'dcc', name: this.showLang('com.but.set.ontime'), img: this.img.light.road.single.lightCtrl.writeTime2 },
      { needArgs: false, needPswd: false, code: 'getInfo', opcode: 'dcs', name: this.showLang('com.but.get.deviceInfo'), img: this.img.light.road.single.lightCtrl.info },
      { needArgs: false, needPswd: false, code: 'getSimInfo', opcode: 'dcs', name: this.showLang('com.but.get.sim'), img: this.img.light.road.single.lightCtrl.readSim },
      { needArgs: true, needPswd: true, code: 'handGroup', opcode: 'dco', name: this.showLang('com.but.set.adjustment'), img: this.img.light.road.single.lightCtrl.groupAdjust },
      { needArgs: false, needPswd: false, code: 'getGroup', opcode: 'dcs', name: this.showLang('com.but.get.packets'), img: this.img.light.road.single.lightCtrl.readGroup },
      { needArgs: true, needPswd: true, code: 'setGroup', opcode: 'dcc', name: this.showLang('com.but.set.packets'), img: this.img.light.road.single.lightCtrl.setGroup },
      { needArgs: false, needPswd: false, code: 'getPlanType', opcode: 'dcs', name: this.showLang('com.but.get.tableType'), img: this.img.light.road.single.lightCtrl.readTimeType },
      { needArgs: true, needPswd: true, code: 'setPlanType', opcode: 'dcc', name: this.showLang('com.but.set.tableType'), img: this.img.light.road.single.lightCtrl.setTimeType },
      { needArgs: false, needPswd: false, code: 'getLuxOpenValue', opcode: 'dcs', name: this.showLang('com.but.get.sensitivity'), img: this.img.light.road.single.lightCtrl.setTimeType },
      { needArgs: true, needPswd: true, code: 'setLuxOpenValue', opcode: 'dcc', name: this.showLang('com.but.set.sensitivity'), img: this.img.light.road.single.lightCtrl.readTimeType },
      { needArgs: false, needPswd: false, code: 'getUnionBright', opcode: 'dcs', name: this.showLang('com.but.get.linkageLight'), img: this.img.light.road.single.lightCtrl.getUnion },
      { needArgs: true, needPswd: true, code: 'setUnionBright', opcode: 'dcc', name: this.showLang('com.but.set.linkageLight'), img: this.img.light.road.single.lightCtrl.setUnion },
      { needArgs: false, needPswd: false, code: 'getUnionParam', opcode: 'dcs', name: this.showLang('com.but.get.parameters'), img: this.img.light.road.single.lightCtrl.getParams },
      { needArgs: true, needPswd: true, code: 'setUnionParam', opcode: 'dcc', name: this.showLang('com.but.set.parameters'), img: this.img.light.road.single.lightCtrl.setParams },
      { needArgs: false, needPswd: false, code: 'getAlarmRange', opcode: 'dcs', name: this.showLang('com.but.get.alarmThreshold'), img: this.img.light.road.single.lightCtrl.getAlarm },
      { needArgs: true, needPswd: true, code: 'setAlarmRange', opcode: 'dcc', name: this.showLang('com.but.set.alarmThreshold'), img: this.img.light.road.single.lightCtrl.setAlarm },
      { needArgs: false, needPswd: false, code: 'getAlarmStatus', opcode: 'dcs', name: this.showLang('com.but.get.alarmStatus'), img: this.img.light.road.single.lightCtrl.getAlarmStatus },
      { needArgs: true, needPswd: true, code: 'setAlarmStatus', opcode: 'dcc', name: this.showLang('com.but.set.alarmStatus'), img: this.img.light.road.single.lightCtrl.setAlarmStatus },
      { needArgs: false, needPswd: false, code: 'getGyroLevel', opcode: 'dcs', name: this.showLang('com.but.get.tiltThreshold'), img: this.img.light.road.single.lightCtrl.getGyro },
      { needArgs: true, needPswd: true, code: 'setGyroLevel', opcode: 'dcc', name: this.showLang('com.but.set.tiltThreshold'), img: this.img.light.road.single.lightCtrl.setGyro },
      { needArgs: false, needPswd: false, code: 'getNetwork', opcode: 'dcs', name: this.showLang('com.but.get.network'), img: this.img.light.road.single.lightCtrl.readNet },
      { needArgs: true, needPswd: true, code: 'setNetwork', opcode: 'dck', name: this.showLang('com.but.set.network'), img: this.img.light.road.single.lightCtrl.setNet },
      { needArgs: true, needPswd: true, code: 'update', opcode: 'dck', name: this.showLang('com.but.set.upgrade'), img: this.img.light.road.single.lightCtrl.update },
      { needArgs: false, needPswd: true, code: 'reset', opcode: 'dco', name: this.showLang('com.but.set.reboot'), img: this.img.light.road.single.lightCtrl.reset },
      { needArgs: false, needPswd: true, code: 'init', opcode: 'dck', name: this.showLang('com.but.initialization'), img: this.img.light.road.single.lightCtrl.init },
      // { needArgs: true, needPswd: true, code: 'monitor', name: '集中器管理', img: this.img.light.road.single.lightCtrl.monitor },
    ]
  },
  methods: {
    docmd: function (item) {
      item.paramsCode = item.code;
      this.$emit('command', item);
    },
    switchMenu: function () {
      this.showMore = !this.showMore
    },
    panelClick: function () {
      return false;
    }
  }
}
</script>
<style scoped>
.info-header {
  border-bottom: solid 1px #dee5ed;
  width: 100%;
  height: 38px;
  flex: none;
  display: flex;
  align-items: center;
}
.info-header-title {
  width: 300px;
  flex: auto;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #021629;
}
.control-table {
  padding: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 2;
  background-color: white;
}
.control-area {
  /* border: solid 1px red; */
  flex: auto;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  align-content: flex-start;
}
.control-item {
  width: 170px;
  height: 90px;
  flex: none;
  margin-left: 80px;
  margin-top: 15px;
  z-index: 2;
  /* border: solid 1px red; */
  display: flex;
  align-items: center;
  cursor: pointer;
}
.control-item img {
  margin-right: 10px;
}
.control-output-op {
  width: 114px;
  height: 37px;
  margin: 15px auto;
  cursor: pointer;
}
.control-table.hideShita {
  height: 710px;
  transition: height 0.5s;
}
.control-table.element {
  height: 195px;
  transition: height 0.5s;
}
</style>