<template>
  <div class="analyse-popup-chart">
    <div class="chart-popup-search">
      {{showLang('com.date.start')}}：
      <DatePicker v-model="form.start" type="datetime" style="width: 180px; margin-right: 10px;"></DatePicker>
       {{showLang('com.date.end')}}：
      <DatePicker v-model="form.end" type="datetime" style="width: 180px; margin-right: 10px;"></DatePicker>
      <Button type="info" @click="loadChert" style="margin-right: 10px;">{{showLang('com.op.query')}}</Button>
    </div>
    <div class="chart-popup-chart">
      <chartsLine :option="chartsLineData" id="popupSavingRate" :key="timer"></chartsLine>
    </div>
    <div class="chart-popup-table">
      <vxe-table  :empty-text="showLang('com.data.no')" border show-footer show-overflow :data="tabData" height="330" :footer-method="footerMethod" :row-config="{isHover: true,height: 34}">
        <vxe-column type="seq" width="50"></vxe-column>
        <vxe-column field="date" title="时间"></vxe-column>
        <vxe-column field="energy" :title="showLang('com.stat.day.energy')"></vxe-column>
        <vxe-column field="rate" :title="showLang('com.stat.saving.rate')"></vxe-column>
        <vxe-column field="pr" :title="showLang('com.stat.rated.energy')"></vxe-column>
        <vxe-column field="money" :title="showLang('com.stat.day.fee')"></vxe-column>
        <vxe-column field="save" :title="showLang('com.stat.save.charge')"></vxe-column>
      </vxe-table>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex'
import { setCookie, getCookie } from '@/plugins/cookie'
Vue.prototype.$cookieStore = { setCookie, getCookie }
export default {
  name: 'DataAnalysis',
  components: {
  },
  props: {
    tabNameType: {
      type: String,
      default: "power",
    }
  },
  data() {
    return {
      timer: "",
      chartsLineData: {
        color: ["#02CB9E", "#A468FE", "#FC58B1", "#5A78FC", "#49B0FD"],
        title: "",
        xColor: "#B5C1CC",
        yColor: "#99A8B6",
        x: [],
        unit: "",
        series: [
          {
            name: "当日能耗(KWh)",
            data: [],
          },
          {
            name: "节电率(%)",
            data: [],
          },
          {
            name: "额定功率(KW)",
            data: [],
          },
          {
            name: "当日费用(元)",
            data: [],
          },
          {
            name: "节省电费(元)",
            data: [],
          },
        ],
        setSeries: {
          smooth: true, //平滑折线图
        },
      },
      timeout: null,
      tabData: [],
      form: {
        start: '',
        end: '',
      },
    }
  },
  computed: {
    ...mapState('cmd', ['cmds', 'cmdResultRefresh', 'windowOnResize', 'lightCmds']),
    ...mapGetters('group', ['groupPathName']),
    ...mapGetters('auth', ['showLang']),
    ...mapState('common', ['weeks', 'productCodes', 'powerBoxTypes', 'lightTopDevices', 'lightMonitorDevices']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
    ...mapState('auth', ['stationTabName']),
  },
  watch: {},
  mounted: function () {
    let now = new Date();
    this.form.end = now.format('yyyy-MM-dd HH:mm:ss');
    now.setDate(now.getDate() - 7);
    this.form.start = now.format('yyyy-MM-dd HH:mm:ss');
    this.widthChange();
    this.debounceInitData();
    this.chartsLineData.series[0].name = this.showLang('com.stat.day.energy');
    this.chartsLineData.series[1].name = this.showLang('com.stat.saving.rate');
    this.chartsLineData.series[2].name=this.showLang('energy.before.energyPower')+'(KW)';
    this.chartsLineData.series[3].name = this.showLang('com.stat.day.fee');
    this.chartsLineData.series[4].name = this.showLang('com.stat.save.charge');
  },
  methods: {
    meanNum(list, field) {
      let count = 0
      list.forEach(item => {
        count += Number(item[field])
      })
      return this.img.val(count / list.length, 2)
    },
    sumNum(list, field) {
      let count = 0
      list.forEach(item => {
        count += Number(item[field])
      })
      return this.img.val(count, 2)
    },
    footerMethod({ columns, data }) {
      // console.log(columns, data)
      return [
        columns.map((column, columnIndex) => {
          if (columnIndex === 0) {
            return this.showLang('com.data.average')
          }
          if (['energy'].includes(column.property)) {
            return this.meanNum(data, column.property)
          }
          if (['rate'].includes(column.property)) {
            return this.meanNum(data, column.property)
          }
          if (['pr'].includes(column.property)) {
            return this.meanNum(data, column.property)
          }
          if (['money'].includes(column.property)) {
            return this.meanNum(data, column.property)
          }
          if (['save'].includes(column.property)) {
            return this.meanNum(data, column.property)
          }
          return null
        }),
        columns.map((column, columnIndex) => {
          if (columnIndex === 0) {
            return this.showLang('com.data.sum')
          }
          if (['energy'].includes(column.property)) {
            return this.sumNum(data, column.property)
          }
          if (['rate'].includes(column.property)) {
            return this.sumNum(data, column.property)
          }
          if (['pr'].includes(column.property)) {
            return this.sumNum(data, column.property)
          }
          if (['money'].includes(column.property)) {
            return this.sumNum(data, column.property)
          }
          if (['save'].includes(column.property)) {
            return this.sumNum(data, column.property)
          }
          return null
        })
      ]
    },
    debounceInitData: function () {
      this.widthChange();
      clearTimeout(this.timeout);
      this.timeout = setTimeout(this.loadChert, 500);
    },
    loadChert() {
      this.powerSaving();//能耗统计 
    },
    powerSaving: async function () {
      this.form.end = new Date(this.form.end).format("yyyy-MM-dd HH:mm:ss");
      this.form.start = new Date(this.form.start).format("yyyy-MM-dd HH:mm:ss");
      let ajaxData = { groupId: 0, stationId: 0, type: this.stationTabName, start: this.form.start, end: this.form.end };
      switch (this.selectedNode.type) {
        case "root":
          ajaxData.groupId = 0;
          ajaxData.stationId = 0;
          break;
        case "group":
          ajaxData.groupId = this.selectedNode.id;
          ajaxData.stationId = 0;
          break;
        case "Station":
          ajaxData.groupId = 0;
          ajaxData.stationId = this.selectedDevices.map(p => p.id)[0];
          break;
      }
      let res = await this.$axios.post(`//${this.domains.trans}/station/analyse/EnergyMore`, ajaxData);
      if (res.code != 0) {
        return false;
      }
      this.chartsLineData.x = [];
      this.chartsLineData.series[0].data = [];
      this.chartsLineData.series[1].data = [];
      this.chartsLineData.series[2].data = [];
      this.chartsLineData.series[3].data = [];
      this.chartsLineData.series[4].data = [];
      res.data.forEach(el => {
        this.chartsLineData.x.push(el.date);
        el.energy = this.img.val(el.energy, 2);
        el.pr = this.img.val(el.pr, 2);
        el.money = this.img.val(el.money, 2);
        el.save = this.img.val(el.save, 2);
        el.rate = this.img.val(el.rate, 2);
        this.chartsLineData.series[0].data.push(el.energy);
        this.chartsLineData.series[1].data.push(el.rate);
        this.chartsLineData.series[2].data.push(el.pr);
        this.chartsLineData.series[3].data.push(el.money);
        this.chartsLineData.series[4].data.push(el.save);
      });
      this.$set(this, 'tabData', res.data);
      this.widthChange();
    },
    widthChange() {
      this.timer = new Date().getTime();
    },
  }
}
</script>
<style scoped>
.analyse-popup-chart {
  display: flex;
  margin-bottom: 20px;
  flex-direction: column;
}
.chart-popup-chart {
  width: 100%;
  height: 300px;
}
.chart-popup-table {
  margin-top: 20px;
  width: 100%;
  height: 300px;
}
</style>