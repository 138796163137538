<template>
  <div>
    <div class="box-header" :style="{backgroundImage: `url(${img.light.road.single.header.bg})`}">
      <div class="header-time">{{showLang('com.date.comm')}}<img :src="img.light.road.single.header.clock"> {{simple.transTime}}</div>
      <div class="header-title">
       {{showLang('com.site.name')}}：{{currentStation.name}}
        <a href="javascript:void(0);" class="header-title-stat" :class="isStation('color')" @click="stationBut()">
          {{isStation('title')}}
          <Tooltip placement="bottom-start" v-if="isStation('title')!=showLang('com.state.run')">
            <img :src="img.clock" alt="" class="header-title-time">
            <div slot="content">
              <p>{{isStation('title')}}{{showLang('com.date.time')}}：{{currentStation.startTime}} </p>
              <p v-if="currentStation.status == 2">{{showLang('com.date.end')}}：{{currentStation.stopTime}} </p>
            </div>
          </Tooltip>
        </a>
        <div class="header-btns">
          <Button v-if="showStationMenu != 'base'" type="default" @click="returnBase">{{showLang('com.op.return')}}</Button>
        </div>
      </div>
      <Modal v-model="stationShow" :title="`${currentStation.name}`">
        <p class="header-popuop-title">{{showLang('com.state')}}</p>
        <Radio-group v-model="stationData.station">
          <Radio :label="showLang('com.state.run')"></Radio>
          <Radio :label="showLang('com.state.service')"></Radio>
          <Radio :label="showLang('com.state.deactivate')"></Radio>
        </Radio-group>
        <template v-if="stationData.station==showLang('com.state.service')">
          <p class="header-popuop-title">{{showLang('com.date.time')}}</p>
          <Input v-model="stationData.time" type="number"><span slot="append">h</span></Input>
        </template>
        <p class="header-popuop-title">{{showLang('com.op.reason')}}</p>
        <Input v-model="stationData.reason" type="textarea" :rows="4" ></Input>
        <div slot="footer">
          <Button style="margin-right: 8px" @click="cancel">{{showLang('com.op.close')}}</Button>
          <AuthButton opCode='ss' size="default" type="primary" @click="ok">{{showLang('com.op.save')}}</AuthButton>
        </div>
      </Modal>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'StationHeader',
  components: {
  },
  props: {
  },
  data() {
    return {
      visible: false,
      stationShow: false,
      stationData: {
        station: "正常",
        time: 0,
        reason: "",
      },
      simple: {
        transTime: '',
      },
    }
  },
  computed: {
    ...mapGetters('group', ['groupPathName']),
    ...mapGetters('auth', ['showLang']),
    ...mapState('common', ['weeks', 'productCodes', 'topDevices']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
    ...mapState('auth', ['showStationMenu']),
    currentStation: function () {
      if (this.selectedNode.type == this.productCodes.station) {
        return this.selectedNode;
      }
      return null;
    },
  },
  watch: {
    selectedNode() {
      this.getStationSimple();
    },
  },
  mounted: function () {
    // console.log('cur sta mounted', this.currentStation)
    this.stationData.station=this.showLang('com.state.run')
    this.getStationSimple();
  },
  destroyed: function () {
  },
  methods: {
    getStationSimple: function(){
      this.$axios.post(`//${this.domains.trans}/station/config/QueryStationSimple`, {id: this.selectedNode.id}).then(res => {
        if (res.code == 0) {
          this.$set(this, 'simple', res.data);
        }
        // console.log("get station simple", res, this.simple)
      });
    },
    cancel: function () {
      this.stationShow = false;
    },
    ok: async function () {
      let status = 1;
      switch (this.stationData.station) {
        case this.showLang('com.state.run'):
          status = 1;
          break;
        case this.showLang('com.state.service'):
          status = 2;
          break;
        case this.showLang('com.state.deactivate'):
          status = 3;
          break;
      }
      let ajaxData = {
        "stationId": this.currentStation.id,
        "status": status,
        "expire": this.stationData.time,
        "reason": this.stationData.reason,
      }
      let res = await this.$axios.post(`//${this.domains.trans}/station/base/SetStatus`, ajaxData);
      if (res.code !== 0) {
        // this.$Message.error(res.data);
        return false;
      }
      this.$Message.info(this.showLang('com.tips.save'));
      let now = new Date();
      let startTime = now.format('yyyy-MM-dd HH:mm:ss');
      now.setHours(now.getHours() + this.stationData.time * 1);
      let stopTime = now.format('yyyy-MM-dd HH:mm:ss');
      this.$store.commit('group/setStationStatus', { status, startTime, stopTime })
      this.stationShow = false;
    },
    stationBut() {
      this.stationData.station = this.isStation('title');
      this.stationShow = true;
    },
    isStation(name) {
      let key = this.currentStation.status;
      // console.log('show station key', this.currentStation, key)
      let title = "", isColor = "";
      switch (key) {
        case 1:
          title = this.showLang('com.state.run');
          isColor = "green"//green
          break;
        case 2:
          title = this.showLang('com.state.service')
          isColor = "red"
          break;
        case 3:
          title = this.showLang('com.state.deactivate')
          isColor = "grey"
          break;
      }
      if (name == "title") {
        return title;
      } else if (name == "color") {
        return isColor;
      }
    },
    returnBase: function () {
      this.$store.commit('auth/setStationMenu', 'base');
    },
    close() {
      this.visible = false;
    }
  }
}
</script>
<style scoped>
.header-info-more {
  height: 20px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  margin-top: -16px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 5px;
}
.header-info-moreBox {
  min-height: 60px;
  padding: 10px;
}
.header-title-stat {
  color: #fff;
  font-size: 16px;
}
.header-title-stat.green {
  color: #2bd58c;
}
.header-title-stat.red {
  color: #f00;
}
.header-title-stat.grey {
  color: #ccc;
}
.header-title-time {
  vertical-align: middle;
  margin-left: 5px;
}
.header-popuop-title {
  margin: 15px 0 5px;
}
.header-btns {
  float: right;
  display: flex;
}
.header-btns button {
  margin-right: 10px;
}
.box-header {
  flex: none;
  height: 65px;
  background-repeat: no-repeat;
  background-size: 100%;
  position: relative;
}
.box-header2 {
  flex: none;
  height: 172px;
  background: #fff;
  margin-top: 16px;
  position: relative;
  padding: 25px 30px;
}
.box-header2-title {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #021629;
  border-bottom: 1px solid #dee5ed;
  padding-bottom: 15px;
}
.header-info-region {
  float: right;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #324252;
  margin-right: 35px;
}
.header-flow {
  position: absolute;
  left: 30px;
  top: 20px;
  width: 60px;
  height: 26px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #fff;
  line-height: 26px;
}
.header-time {
  position: absolute;
  left: 18px;
  top: 20px;
  width: 281px;
  height: 26px;
  background: #ffffff;
  border-radius: 13px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #3880fc;
  line-height: 26px;
  text-indent: 8px;
}
.header-time img {
  vertical-align: middle;
  margin: -3px 3px 0 10px;
}
.header-wifi {
  position: absolute;
  right: 30px;
  top: 10px;
  /* width: 360px; */
  height: 26px;
  /* background: #FFFFFF; */
  border-radius: 13px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 26px;
}
.header-wifi img {
  vertical-align: middle;
  margin: -3px 5px 0 10px;
}
.header-title {
  font-size: 26px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  margin: 10px 0 15px 0;
  padding-top: 12px;
}
.header-info {
  color: #324252;
  display: flex;
  justify-content: space-evenly;
  padding: 18px 0 5px;
}
.header-info-item {
  flex: auto;
  text-align: left;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #324252;
}
.header-info-item img {
  width: 14px;
  height: 14px;
  vertical-align: middle;
  margin-right: 3px;
  margin-top: -3px;
}
.header-info-region img {
  width: 14px;
  height: 14px;
  vertical-align: middle;
  margin-right: 3px;
  margin-top: -3px;
}
</style>