<template>
  <Modal v-model="showModal" :mask-closable="false" width="600px">
    <div slot="header">{{title}}</div>
    <Form ref="form" :model="form" :label-width="120">
      <FormItem prop="parentId" :label="showLang('com.superior.dev')">
        <Select :placeholder="showLang('save.select')"  ref="parentId" v-model="form.parentId" number>
          <Option :value="0">{{showLang('com.state.not')}}</Option>
          <template v-for="(item, idx) in topDevices">
            <Option :value="item.id" :key="idx">{{types[item.type]}}：{{item.name}}[ID:{{item.code}}]</Option>
          </template>
        </Select>
      </FormItem>
    </Form>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">{{showLang('com.op.cancel')}}</Button>
      <Button type="primary" :loading="loading" @click="ok">{{showLang('com.op.save')}}</Button>
    </div>
  </Modal>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'ModalBatchTopDevice',
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default() { return {}; }
    },
    types: {
      type: Object,
      default() { return {}; }
    },
    devices: {
      type: Array,
      default() { return []; }
    },
  },
  data() {
    return {
      title: '编辑设备信息',
      loading: false,
      showModal: this.value,
      isAdd: false,
      form: {
        parentId: 0,
      },
      topDevices: [],
    }
  },
  computed: {
    ...mapGetters('auth', ['showLang']),
    ...mapState('auth', ['roles', 'customers', 'appScenes']),
    ...mapState('common', ['productCodes', 'deviceTypes', 'bigTypes', 'smallTypes', 'coms8088names']),
    ...mapGetters('common', ['getBigTypeName', 'getSmallTypeName', 'getSmallTypes']),
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if(newVal){
        let tops = this.devices.filter(p => p.bigType == 2);
        this.$set(this, 'topDevices', tops);
      }
    },
    showModal(val) {
      this.$emit('showChanged', val);
    }
  },
  mounted: function () {
    this.title=this.showLang('com.but.edit.deviceInfo');
  },
  methods: {
    // getTops: function(){
    //   if(!this.form.stationId){
    //     this.$set(this, "topDevices", []);
    //     return;
    //   }
    //   this.$axios.post(`//${this.domains.trans}/station/config/QueryParentDevices`, {stationId: this.form.stationId}).then(res => {
    //     if(res.code != 0)return;
    //     this.$set(this, "topDevices", res.data);
    //   });
    // },
    ok: async function () {
      // console.log('batch save top', this.item, this.form);
      this.loading = true;
      this.$axios.post(`//${this.domains.trans}/station/config/BatchSetDevice`, {
        ids: this.item.ids,
        parentId: this.form.parentId
      }).then(res => {
        this.loading = false;
        if (res.code == 0) {
          this.$Message.info(this.showLang('com.tips.save'));
          setTimeout(() => {
            this.showModal = false;
            this.$emit('saved');
          }, 800);
        }
      });
    },
    cancel() {
      this.showModal = false;
    },
  }
}
</script>