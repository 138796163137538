import { render, staticRenderFns } from "./PowerSecurityTable.vue?vue&type=template&id=0fba024c&scoped=true&"
import script from "./PowerSecurityTable.vue?vue&type=script&lang=js&"
export * from "./PowerSecurityTable.vue?vue&type=script&lang=js&"
import style0 from "./PowerSecurityTable.vue?vue&type=style&index=0&id=0fba024c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0fba024c",
  null
  
)

export default component.exports