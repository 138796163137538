<template>
  <Modal v-model="showModal" :mask-closable="false" width="1200px">
    <div slot="header">{{showLang('com.device.box')}} {{currentPower ? currentPower.name : ''}} {{showLang('history.data')}}</div>
    <Form ref="form" :label-width="0" >
      <FormItem :label="showLang('com.date.time')" :label-width="80">
        <div style="display: flex">
          <DatePicker type="datetime" v-model="filter.start" format="yyyy-MM-dd HH:mm" style="width: 155px; margin-right: 10px"></DatePicker>
          {{showLang('com.date.to')}}
          <DatePicker type="datetime" v-model="filter.end" format="yyyy-MM-dd HH:mm" style="width: 155px; margin-left: 10px"></DatePicker>
               <Button type="primary" style="margin-left: 10px" @click="search">{{showLang('com.op.query')}}</Button>
          <Button type="primary" style="margin-left: 10px" @click="exportData">{{showLang('com.op.export')}}</Button>
          <span style="margin-left: 20px">{{showLang('com.data.sum.record',history.length)}}</span>
        </div>
      </FormItem>
      <FormItem >
        <LineChart ref="lineChart1" :config="config" :id="`pb-branch-${chartId}`" style="width: 1168px;height: 300px"  :key="formItemKey"/>
      </FormItem>
      <FormItem>
        <div class="branch-table">
          <table id="tabMainDataHistory" class="table">
            <tr>
              <td class="column-header" style="width: 200px" >{{showLang('com.date.occurrence')}}</td>
              <td class="column-header" style="width:120px" >{{showLang('cmd.project')}}</td>
              <td class="column-header" style="width:120px" >{{showLang('cmd.project.A')}}</td>
              <td class="column-header" style="width:120px" >{{showLang('cmd.project.B')}}</td>
              <td class="column-header" style="width:120px" >{{showLang('cmd.project.C')}}</td>
              <td class="column-header" style="width:120px" >{{showLang('cmd.project')}}</td>
              <td class="column-header" style="width:120px" >{{showLang('cmd.project.A')}}</td>
              <td class="column-header" style="width:120px" >{{showLang('cmd.project.B')}}</td>
              <td class="column-header" style="width:120px" >{{showLang('cmd.project.C')}}</td>
            </tr>
            <template v-for="(branch, index) in history">
              <tr :key="`data-${index}-0`">
                <td class="column-content" rowspan="5">{{new Date(branch.fireTime).format('yyyy-MM-dd HH:mm:ss')}}</td>
                <td class="column-content">{{showLang('com.lighting.V')}}</td>
                <td class="column-content">{{uval(branch, 'a')}}</td>
                <td class="column-content">{{uval(branch, 'b')}}</td>
                <td class="column-content">{{uval(branch, 'c')}}</td>
                <td class="column-content">{{showLang('com.lighting.par')}}</td>
                <td class="column-content">{{itemInfo.main.par}} W</td>
                <td class="column-content">{{itemInfo.main.par}} W</td>
                <td class="column-content">{{itemInfo.main.par}} W</td>
              </tr>
              <tr :key="`data-${index}-1`">
                <td class="column-content">{{showLang('com.lighting.I')}}</td>
                <td class="column-content">{{cval(branch, 'a')}}</td>
                <td class="column-content">{{cval(branch, 'b')}}</td>
                <td class="column-content">{{cval(branch, 'c')}}</td>
                <td class="column-content">{{showLang('com.data.load')}}</td>
                <td class="column-content">{{pval(branch, 'a')}}</td>
                <td class="column-content">{{pval(branch, 'b')}}</td>
                <td class="column-content">{{pval(branch, 'c')}}</td>
              </tr>
              <tr :key="`data-${index}-2`">
                <td class="column-content">{{showLang('com.data.power.factor')}}</td>
                <td class="column-content">{{fval(branch, 'a')}}</td>
                <td class="column-content">{{fval(branch, 'b')}}</td>
                <td class="column-content">{{fval(branch, 'c')}}</td>
                <td class="column-content">{{showLang('com.data.peak.load')}}</td>
                <td class="column-content" colspan="3">
                  <template v-if="itemInfo.main.lastData">
                    {{(itemInfo.main.extraData.hpv*1).toFixed(2)}} W <span style="margin-left: 20px">[{{new Date(itemInfo.main.extraData.hpt).format('yyyy-MM-dd HH:mm:ss')}}]</span>
                  </template>
                </td>
              </tr>
              <tr :key="`data-${index}-3`">
                <td class="column-content">{{showLang('com.data.active.power')}}</td>
                <td class="column-content">{{pval(branch, 'a')}}</td>
                <td class="column-content">{{pval(branch, 'b')}}</td>
                <td class="column-content">{{pval(branch, 'c')}}</td>
                <td class="column-content"></td>
                <td class="column-content" colspan="3"></td>
              </tr>
              <tr :key="`data-${index}-4`">
                <td class="column-content">{{showLang('com.data.reactive.power')}}</td>
                <td class="column-content">{{rpval(branch, 'a')}}</td>
                <td class="column-content">{{rpval(branch, 'b')}}</td>
                <td class="column-content">{{rpval(branch, 'c')}}</td>
                <td class="column-content"></td>
                <td class="column-content" colspan="3"></td>
              </tr>
            </template>
          </table>
        </div>
      </FormItem>
    </Form>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">{{showLang('com.op.close')}}</Button>
    </div>
  </Modal>
</template>
<script>
import XLSX from 'xlsx'
import FileSaver from 'file-saver'
import { mapGetters, mapState } from 'vuex'
import LineChart from '@/components/charts/LineChart'
export default {
  name: 'ModalMainData',
  components: {
    LineChart,
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    itemInfo: {
      type: Object,
      default() { return {}; }
    },
  },
  data() {
    return {
      showModal: false,
      filter: {
        start: '',
        end: '',
      },
      history: [],
      loading: false,
      config: {
        title: `总配电历史数据`
      },
      isEnergy: false,
      chartId: 0,
      formItemKey:0,
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      this.config.title=this.showLang('history.data')
      if (newVal) {
        if(this.itemInfo.showe && this.itemInfo.energy && this.itemInfo.energy.lastData){
          this.isEnergy = true;
        }else{
          this.isEnergy = false;
        }
        if(this.isEnergy){
          this.chartId = this.itemInfo.energy.id;
        }else{
          this.chartId = this.itemInfo.main.id;
        }
        let now1 = new Date();
        now1.setDate(now1.getDate() - 7);
        this.filter.start = now1;
        this.filter.end = new Date();
        this.search();
      }
    },
    showModal(val) {
      this.$emit('showChanged', val);
    }
  },
  computed: {
    ...mapState('cmd', ['singleCmdResult']),
    ...mapGetters('group', ['groupPathName']),
    ...mapGetters('auth', ['showLang']),
    ...mapState('common', ['weeks', 'productCodes', 'powerBoxTypes']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
    currentPower: function () {
      let ps = this.selectedDevices.filter(p => p.id == this.selectedNode.id);
      if (ps.length > 0) return ps[0];
      return null;
    },
    uval: function(){
      return function(item, flag){
        let u = -1;
        if(this.isEnergy){
          u = item.content[`u${flag}`];
        }else{
          u = item.content[`u${flag}`];
        }
        if(u < 0)return '-';
        let low = this.itemInfo.main.content[`u${flag}r`] * this.itemInfo.main.content[`u${flag}l`] / 100;
        let high = this.itemInfo.main.content[`u${flag}r`] * this.itemInfo.main.content[`u${flag}h`] / 100;
        u = this.img.val(u, 1);
        return `${u}(${low} - ${high})V`
      }
    },
    cval: function(){
      //{{img.val(mitem.lastData.ca, 1)}} ({{img.val(mitem.content.car * mitem.content.cal / 100, 1)}} - {{img.val(mitem.content.car * mitem.content.cah / 100, 1)}}) A
      return function(item, flag){
        let c = -1;
        if(this.isEnergy){
          c = item.content[`c${flag}`];
        }else{
          c = item.content[`c${flag}`];
        }
        if(c < 0)return '-';
        let low = this.itemInfo.main.content[`c${flag}r`] * this.itemInfo.main.content[`c${flag}l`] / 100;
        let high = this.itemInfo.main.content[`c${flag}r`] * this.itemInfo.main.content[`c${flag}h`] / 100;
        c = this.img.val(c, 1);
        low = this.img.val(low, 3);
        high = this.img.val(high, 3);
        return `${c}(${low} - ${high}) A`
      }
    },
    pval: function(){
      //({{img.val(mitem.lastData.pc * 100 / mitem.content.pcr, 1)}} %)
      return function(item, flag){
        let p = -1;
        if(this.isEnergy){
          p = item.content[`ap${flag}`];
        }else{
          p = item.content[`p${flag}`];
        }
        if(p < 0)return '-';
        let high = this.img.val(p * 100 / this.itemInfo.main.content[`p${flag}r`], 1);
        p = this.img.val(p, 1);
        return `${p}W(${high}%)`
      }
    },
    fval: function(){
      return function(item, flag){
        let f = -1;
        if(this.isEnergy){
          f = item.content[`f${flag}`];
        }else{
          f = item.content[`f${flag}`];
        }
        if(f < 0)return '-';
        let high = this.itemInfo.main.content[`p${flag}f`];
        f = this.img.val(f, 3);
        return `${f}(${high})`
      }
    },
    rpval: function(){
      return function(item, flag){
        if(this.isEnergy){
          return item.content[`rp${flag}`] + 'Var';
        }else{
          let u = item.content[`u${flag}`];
          let c = item.content[`c${flag}`];
          let p = item.content[`p${flag}`];
          if(!u || !c || !p)return '--';
          if(u < 0 || c < 0 ||  p< 0)return '-';
          return (u * c - p).toFixed(2) + 'Var';
        }
      }
    },
  },
  mounted(){
    this.formItemKey++
  },
  methods: {
    np: function(u, c, p){
      if(!u || !c || !p)return '--';
      if(u < 0 || c < 0 ||  p< 0)return '-';
      return (u * c - p).toFixed(2);
    },
    exportData: function () {
      var xlsxParam = { raw: true };//转换成excel时，使用原始的格式
      var wb = XLSX.utils.table_to_book(document.querySelector("#tabMainDataHistory"), xlsxParam);//outTable为列表id
      var wbout = XLSX.write(wb, {
        bookType: "xlsx",
        bookSST: true,
        type: "array"
      });
      try {
        FileSaver.saveAs(
          new Blob([wbout], { type: "application/octet-stream;charset=utf-8" }),
          "sheetjs.xlsx"
        );
      } catch (e) {
        // if (typeof console !== "undefined") console.log(e, wbout);
      }
      return wbout;
    },
    currentStatus: function (params, type) {
      if (params.timeValue == 1 && params[`current${type}Value`] < (params[`current${type}Rated`] * params[`current${type}Low`] / 100)) return this.showLang('alarm.type.current.under');
      if (params.timeValue == 1 && params[`current${type}Value`] > (params[`current${type}Rated`] * params[`current${type}High`] / 100)) return this.showLang('alarm.type.current.over');
      return this.showLang('com.state.normal');
    },
    currentVStatus: function (params, type) {
      if (params.timeValue == 1 && params[`voltage${type}Value`] < (params[`voltage${type}Rated`] * params[`voltage${type}Low`] / 100)) return this.showLang('alarm.type.voltage.under');
      if (params.timeValue == 1 && params[`voltage${type}Value`] > (params[`voltage${type}Rated`] * params[`voltage${type}High`] / 100)) return this.showLang('alarm.type.voltage.over');
      return this.showLang('com.state.normal');
    },
    search: async function () {
      // console.log(this.itemInfo)
      let pid = 0;
      if(this.isEnergy){
        pid = this.itemInfo.energy.id;
      }else{
        pid = this.itemInfo.main.id;
      }
      let ajaxData = {
        // sid: this.itemInfo.stationId,//站点ID stationId
        paramId: pid,//属性ID
        start: this.filter.start.format('yyyy-MM-dd HH:mm:00'),
        end: this.filter.end.format('yyyy-MM-dd HH:mm:59')
      }
      let res = await this.$axios.post(`//${this.domains.trans}/station/param/history`, ajaxData);
      if (res.code !== 0) {
        // this.$Message.error(res.data);
        return;
      }
      // console.log('get his data', this.isEnergy, this.itemInfo, pid, res.data)
      this.$set(this, "history", res.data);
      let legend = { type: 'scroll', right: 50, data: [this.showLang('cmd.project.A.V'), this.showLang('cmd.project.B.V'), this.showLang('cmd.project.C.V'), this.showLang('cmd.project.A.I'), this.showLang('cmd.project.B.I'), this.showLang('cmd.project.C.I'), this.showLang('cmd.project.A.P'), this.showLang('cmd.project.B.P'), this.showLang('cmd.project.C.P')] };
      let series = [
        { name: this.showLang('cmd.project.A.V')+'(V)', type: 'line', yAxisIndex: 0, smooth: true, symbol: 'none', data: [] },
        { name: this.showLang('cmd.project.B.V')+'(V)', type: 'line', yAxisIndex: 0, smooth: true, symbol: 'none', data: [] },
        { name: this.showLang('cmd.project.C.V')+'(V)', type: 'line', yAxisIndex: 0, smooth: true, symbol: 'none', data: [] },
        { name: this.showLang('cmd.project.A.I')+'(A)', type: 'line', yAxisIndex: 0, smooth: true, symbol: 'none', data: [] },
        { name: this.showLang('cmd.project.B.I')+'(A)', type: 'line', yAxisIndex: 0, smooth: true, symbol: 'none', data: [] },
        { name: this.showLang('cmd.project.C.I')+'(A)', type: 'line', yAxisIndex: 0, smooth: true, symbol: 'none', data: [] },
        { name: this.showLang('cmd.project.A.P')+'(W)', type: 'line', yAxisIndex: 1, smooth: true, symbol: 'none', data: [] },
        { name: this.showLang('cmd.project.B.P')+'(W)', type: 'line', yAxisIndex: 1, smooth: true, symbol: 'none', data: [] },
        { name: this.showLang('cmd.project.C.P')+'(W)', type: 'line', yAxisIndex: 1, smooth: true, symbol: 'none', data: [] },
      ];
      let yAxis = [
        {
          type: 'value',
          // boundaryGap: true,
          boundaryGap: ['20%', '20%']
        },
        {
          type: 'value',
          // boundaryGap: true,
          boundaryGap: ['20%', '20%']
        },
        {
          type: 'value',
          boundaryGap: ['20%', '20%'],
          // min: 0,
          // max: 25,
          // interval: 5,
          // axisLabel: {
          //     formatter: '{value} °C'
          // }
        }
      ];
      let grid = {
        bottom: 30,
        left: 60,
        right: 50,
        backgroundColor: '#ffffff',
        show: true
      };
      for (let row of res.data) {
        series[3].data.push([new Date(row.fireTime).format('yyyy-MM-dd HH:mm:ss'), row.content.ca >= 0 ? row.content.ca : 0]);
        series[4].data.push([new Date(row.fireTime).format('yyyy-MM-dd HH:mm:ss'), row.content.cb >= 0 ? row.content.cb : 0]);
        series[5].data.push([new Date(row.fireTime).format('yyyy-MM-dd HH:mm:ss'), row.content.cc >= 0 ? row.content.cc : 0]);

        series[0].data.push([new Date(row.fireTime).format('yyyy-MM-dd HH:mm:ss'), row.content.ua >= 0 ? row.content.ua : 0]);
        series[1].data.push([new Date(row.fireTime).format('yyyy-MM-dd HH:mm:ss'), row.content.ub >= 0 ? row.content.ub : 0]);
        series[2].data.push([new Date(row.fireTime).format('yyyy-MM-dd HH:mm:ss'), row.content.uc >= 0 ? row.content.uc : 0]);

        if(this.isEnergy){
          series[6].data.push([new Date(row.fireTime).format('yyyy-MM-dd HH:mm:ss'), row.content.apa >= 0 ? row.content.apa : 0]);
          series[7].data.push([new Date(row.fireTime).format('yyyy-MM-dd HH:mm:ss'), row.content.apb >= 0 ? row.content.apb : 0]);
          series[8].data.push([new Date(row.fireTime).format('yyyy-MM-dd HH:mm:ss'), row.content.apc >= 0 ? row.content.apc : 0]);
        }else{
          series[6].data.push([new Date(row.fireTime).format('yyyy-MM-dd HH:mm:ss'), row.content.pa >= 0 ? row.content.pa : 0]);
          series[7].data.push([new Date(row.fireTime).format('yyyy-MM-dd HH:mm:ss'), row.content.pb >= 0 ? row.content.pb : 0]);
          series[8].data.push([new Date(row.fireTime).format('yyyy-MM-dd HH:mm:ss'), row.content.pc >= 0 ? row.content.pc : 0]);
        }
      }
      this.$refs.lineChart1.loadData(series, legend, yAxis, grid);
    },
    cancel() {
      this.showModal = false;
    },
  }
}
</script>
<style scoped>
.demo-drawer-footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
}
.form {
  height: calc(100% - 54px);
  overflow: auto;
}
.readonly {
  background: #eee;
}
.branch-table {
  height: 300px;
  overflow: auto;
}
.table {
  border-collapse: collapse;
  width: 100%;
}
.table-header {
  /* width: 1064px; */
  height: 47px;
  text-align: center;
  background: rgba(10, 26, 68, 0.8);
  box-shadow: inset 0px 0px 24px 0px #04559d;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #52fbfe;
  border: solid 1px black;
}
.table-header img {
  text-align: center;
  vertical-align: middle;
  margin-right: 3px;
}
.column-header {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  opacity: 0.8;
  border: solid 1px black;
  text-align: center;
  height: 30px;
  /* width: 130px; */
  /* border-collapse: collapse; */
  background: #183992;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.column-header img {
  vertical-align: middle;
  margin-right: 10px;
}
.column-content {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #050505;
  background: #2c9ef55e;
  border: solid 1px black;
  text-align: center;
  height: 35px;
  /* width: 130px; */
}
</style>