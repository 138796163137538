<template>
  <div>
    <template v-for="item in list">
      <a href="javascript:void(0);" v-if="(onlyLeakage && leakageMenus.indexOf(item.code) == -1 || !onlyLeakage) && funCodes(item.opcode)" class="control-item" :key="item.code" @click="doTypeCmd(item)">
        <img :src="item.img" />
        {{showLang(item.lang)}}
      </a>
    </template>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
export default {
  name: 'butList',
  components: {
  },
  props: {
    item: {
      type: Object,
      default() { return {}; }
    },
    selected: {
      type: Object,
      default() { return {}; }
    },
    refresh: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      leakageMenus: ['openControl', 'getYearPlan', 'setYearPlan'],
      list: [],
      items: [
        { needArgs: true, needPswd: true, code: 'openControl', opcode: 'dco', lang:'com.but.switch.control',name: '开关控制', img: this.img.light.map.button.switch },
        { needArgs: false, needPswd: false, code: 'forceRead', opcode: 'dcs', lang:'com.but.survey',name: '召测数据', img: this.img.light.map.button.survey },
        { needArgs: false, needPswd: false, code: 'getclock', opcode: 'dcs', lang:'com.but.get.read.clock',name: '读取时钟', img: this.img.light.map.button.read },
        { needArgs: false, needPswd: true, code: 'setclock', opcode: 'dcc', lang:'com.but.get.Calibrate',name: '校准时钟', img: this.img.light.map.button.correct },
        { needArgs: true, needPswd: false, code: 'getYearPlan', opcode: 'dcs', lang:'com.but.get.time.tab',name: '读取时间表', img: this.img.light.map.button.time },
        { needArgs: true, needPswd: true, code: 'setYearPlan', opcode: 'dcc', lang:'com.but.set.time.tab',name: '下发时间表', img: this.img.light.map.button.Issued },
      ],
      items2: [
        { needArgs: false, needPswd: false, code: 'forceRead', opcode: 'dcs', lang:'com.but.survey',name: '召测数据', img: this.img.light.map.button.survey },
        { needArgs: true, needPswd: true, code: 'handSingle', opcode: 'dco', lang:'com.but.dimming',name: '调光控制', img: this.img.light.map.button.control },
        { needArgs: true, needPswd: true, code: 'openSingle', opcode: 'dco', lang:'com.but.open.lamp',name: '开灯', img: this.img.light.map.button.onLight },
        { needArgs: true, needPswd: true, code: 'closeSingle', opcode: 'dco', lang:'com.but.close.lamp',name: '关灯', img: this.img.light.map.button.offLight },
        { needArgs: false, needPswd: false, code: 'getclock', opcode: 'dcs', lang:'com.but.get.QueryClock',name: '查询时钟', img: this.img.light.map.button.time },
        { needArgs: false, needPswd: true, code: 'setclock', opcode: 'dcc', lang:'com.but.get.Calibrate',name: '校准时钟', img: this.img.light.map.button.correct },
      ],
      // selected: {},
      cmdItem: {},
      showArgModal: false,
      cmdIds: [],
      stationTabName: "power",
    }
  },
  watch: {
    refresh() {
      this.isType();
    },
  },
  computed: {
    ...mapGetters('auth', ['funCodes','showLang']),
    ...mapState('common', ['weeks', 'productCodes', 'powerBoxTypes', 'topDevices', 'deviceTypes', 'powerTableActions', 'monitorActionTypes']),
    ...mapState('timeTable', ['powerTableActions']),
    ...mapState("cmd", ["commands", "cmdResultRefresh"]),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
    onlyLeakage: function () {
      return this.selectedNode.type == this.productCodes.station && !this.selectedNode.hasControl;
    },
  },
  mounted: function () {
    
  },
  destroyed: function () {
  },
  methods: {
    isType() {
      if (this.item.type == 0 && this.selected.main) {
        this.$set(this, "list", this.items);
        this.stationTabName = "power"
        return;
      }
      if (this.item.type == 199 && this.selected && this.selected.light && this.selected.light.lastData) {
        this.$set(this, "list", this.items2)
        this.stationTabName = "light"
      }
    },
    doTypeCmd(cmd) {
      if (this.stationTabName == "power") {
        this.doPowerCmd(cmd)
      } else {
        this.doLightCmd(cmd)
      }
    },
    doLightCmd: function (cmd) {
      let list = []; list.push(this.selected.light);
      if (list.length == 0) {
        this.$Modal.error({
          title: this.showLang('com.ins.arrange.wrong'),
          content: this.showLang('com.ins.select.lamp.controller')
        });
        return;
      }
      if (cmd.needArgs || cmd.needPswd) {
        cmd.type = 'light';
        this.cmdItem = cmd;
        this.showArgModal = true;
        this.$emit('savedShow', { cmd: cmd, type: false });
      } else {
        let cmds = [], cmdIds = [];
        cmdIds.push(list[0].id);
        cmds.push({ id: list[0].id, code: cmd.code, name: cmd.name, deviceId: list[0].deviceId, args: {}, source: "gis" });
        this.$set(this, 'cmdIds', cmdIds);
        this.$emit('savedShow', { cmd: cmdIds, type: true });
        this.$store.dispatch('cmd/sendCommands', cmds).then(res => {
          if (res.code != 0) {
            this.$Modal.error({
              title:this.showLang('com.ins.sending.error'),
              content: res.data
            });
          }
        });
      }
    },
    doPowerCmd: function (cmd) {
      let devs = [];
      devs.push(this.selected.main.deviceId)
      if (devs.length == 0) {
        this.$Modal.error({
          title: this.showLang('com.ins.arrange.wrong'),
          content:this.showLang('com.ins.no.attribute.device')
        });
        return;
      }
      if (cmd.needArgs || cmd.needPswd) {
        cmd.type = 'power';
        cmd.devs = devs;
        this.cmdItem = cmd;
        this.showArgModal = true;
        this.$emit('savedShow', { cmd: cmd, type: false });
      } else {
        let cmds = [{ id: devs[0], code: cmd.code, name: cmd.name, deviceId: devs[0], args: {}, source: "gis" }];
        this.$set(this, 'cmdIds', [devs[0]]);
        this.$emit('savedShow', { cmd: this.cmdIds, type: true });
        this.$store.commit('auth/showSpin', { content: this.showLang('com.ins.send.wait.reply'), timeout: 20 });
        this.$store.dispatch('cmd/sendCommands', cmds, "/gis/bugtton").then(res => {
          if (res.code != 0) {
            this.$Modal.error({
              title: this.showLang('com.ins.sending.error'),
              content: res.data
            });
          }
        });
      }
    },
  }
}
</script>
<style scoped>
.control-item {
  height: 36px;
  background: #3880fc;
  border-radius: 6px;
  display: inline-block;
  text-align: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 36px;
  margin: 10px 20px 10px 0;
  padding: 0 18px;
}
.control-item img {
  display: inline-block;
  vertical-align: middle;
}
</style>