<template>
<div class="search-data-container">
  <div class="search-data-header">
    <div class="search-data-query">
      {{showLang('com.device.box.own')}}：
      <Select :placeholder="showLang('save.select')" v-model="form.stationId" style="width: 150px; margin-right: 10px;">
        <!-- <Option :value="0">所有配电柜</Option> -->
        <template v-for="(item, idx) in selectedStations">
          <Option :value="item.id" :key="idx">{{item.name}}</Option>
        </template>
      </Select>
      <!-- 通信设备：
      <Select :placeholder="showLang('save.select')" v-model="form.deviceId" style="width: 250px; margin-right: 10px;">
        <Option :value="0">所有设备</Option>
        <template v-for="(item, idx) in stationDevices">
          <Option :value="item.id" :key="idx">{{item.name}}</Option>
        </template>
      </Select> -->
      {{showLang('com.date.start')}}：
      <DatePicker v-model="form.start" type="datetime" style="width: 170px; margin-right: 10px;"></DatePicker>
      {{showLang('com.date.end')}}：
      <DatePicker v-model="form.end" type="datetime" style="width: 170px; margin-right: 10px;"></DatePicker>
      <Button v-if="funCodes('stsv')" type="info" :loading="loading" @click="getList" style="margin-right: 10px;">{{showLang('com.op.query')}}</Button>
      <!-- <Button v-if="funCodes('stse')" type="success" @click="exportData">{{showLang('com.op.export')}}</Button> -->
    </div>
  </div>
  <div class="search-data-content" ref="table1">
    <vxe-table
      :empty-text="showLang('com.data.no')"
      border
      resizable
      show-overflow
      show-header-overflow
      keep-source
      ref="chkTable"
      :data="tabData"
      :height="tabHeight"
      :row-config="{ isHover: true }"
      row-id="id">
      <vxe-column type="seq" width="60" :title="showLang('com.export.cn.seq')"></vxe-column>
      <vxe-column field="stationName" title="站点名称" header-align="center" sortable></vxe-column>
      <vxe-column field="deviceName" title="设备名称" header-align="center" sortable></vxe-column>
      <vxe-column field="deviceCode" title="通信ID" width="150" header-align="center" sortable></vxe-column>
      <vxe-column field="createTime" title="发生时间" width="150" header-align="center" sortable></vxe-column>
      <vxe-column field="extra" title="是否在线" width="150" header-align="center" sortable>
        <template #default="{ row }">
          {{ row.online ? "在线" : "离线" }}
        </template>
      </vxe-column>
    </vxe-table>
    <vxe-pager :layouts="['Sizes', 'PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump', 'Total']" :current-page.sync="form.index" :page-size.sync="form.size" :total="total" @page-change="handlePageChange"  :page-sizes='pageSizes'> </vxe-pager>
    <Spin size="large" fix v-if="loading"></Spin>
  </div>
</div>
</template>
<script>
import { mapState,mapGetters } from 'vuex'
import exportXlsx from '@/components/xlsx/exportXlsx'
export default {
  name: 'StationConfigIndex',
  components:{
  },
  props: {
  },
  data () {
    return {
      loading: false,
      curTab: 'group',
      tabHeight:1,
      tabData:[],
      pageSizes: [20, 100, 500, 1000, 5000],
      tabHead:{ //头部标题
        sname: "站点名称",
        code: '设备ID',
        dname: "设备名称",
        ts: "发生时间",
        online: "是否在线",
      },
      total: 0,
      form:{
        stationId: 0,
        deviceId: 0,
        start:"",
        end:"",
        index: 1,
        size: 20
      },
      history: [],
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes','showLang']),
    ...mapState('cmd', ['windowOnResize', 'cmds']),
    ...mapState('common', ['weeks', 'productCodes', 'alarmTypes', 'alarmLevels']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
    selectedStations: function(){
      return this.selectedDevices.filter(p => p.type == this.productCodes.station);
    },
    selectedStationIds: function(){
      let pids = this.selectedDevices.filter(p => p.type == this.productCodes.station).map(p => {return p.id});
      return pids;
    },
  },
  watch: {
    selectedNode(){
      this.setDefaultStation();
    },
    windowOnResize(){
      this.setTableHeight();
    },
  },
  mounted: function(){
    let now = new Date();
    this.form.end = now.format('yyyy-MM-dd HH:mm:ss');
    now.setDate(now.getDate() - 3);
    this.form.start = now.format('yyyy-MM-dd HH:mm:ss');
    setTimeout(() => {
      this.setTableHeight();
    }, 100);
    this.setDefaultStation();
  },
  destroyed: function(){
  },
  methods: {
    handlePageChange({ currentPage, pageSize }) {
      this.form.index = currentPage
      this.form.size = pageSize
      this.getList()
    },
    setTableHeight: function(){
      this.tabHeight = this.$refs.table1.clientHeight - 55;
    },
    setDefaultStation: function(){
      if (this.selectedStations.length == 0){
        this.form.stationId = 0;
        return;
      }
      if (this.form.stationId == 0) {
        this.form.stationId = this.selectedStations[0].id;
        return;
      }
      let ss = this.selectedStations.filter(p => p.id == this.form.stationId)
      if (ss.length == 0) {
        this.form.stationId = this.selectedStations[0].id;
      }
    },
    exportData(){
      let dataArr=JSON.parse(JSON.stringify(this.tabData));
      let name = this.showLang('history.data.comm.export');
      // let vs = this.views.filter(p => p.code == this.viewCode);
      // if(vs.length > 0){
      //   name = `${vs[0].name}数据导出`;
      // }
      exportXlsx(dataArr, this.tabHead, name);
    },
    getList(){
      this.form.start = new Date(this.form.start).format("yyyy-MM-dd HH:mm:ss");
      this.form.end = new Date(this.form.end).format("yyyy-MM-dd HH:mm:ss");
      // let params = {sid: this.form.stationId, deviceId: this.form.deviceId, start: this.form.start, end: this.form.end};
      this.loading = true;
      this.$axios.post(`//${this.domains.trans}/station/search/online`, this.form).then(res => {
        this.loading = false;
        if(res.code == 0){
          // res.data.list.map(item => item.online = item.online ? this.showLang('com.state.onLine') : this.showLang('com.state.offline'))
          this.$set(this, 'tabData', res.data.list);
          this.total = res.data.count;
        }
      });
    },
    switchTab: function(type){
      this.curTab = type;
    },
  }
}
</script>
<style scoped>
.search-data-container{
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  /* border: solid 1px blue; */
}
.search-data-header{
  height: 40px;
  flex: none;
  display: flex;
  /* border: solid 1px blue; */
}
.search-data-content{
  height: 100px;
  flex: auto;
  /* border: solid 1px blue; */
}
.search-data-query{
  width: 830px;
  flex: auto;
  background-color: white;
  /* margin: 3px 1px; */
  display: flex;
  align-items: center;
  padding: 10px;
  /* border: solid 1px blue; */
  white-space: nowrap;
}
.search-data-view{
  width: 300px;
  flex: auto;
  display: flex;
  align-items: center;
  /* border: solid 1px blue; */
  white-space: nowrap;
  padding: 10px;
}
</style>