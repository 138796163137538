<template>
  <div class="config-group-container">
    <div class="data-area" ref="table">
      <!-- <Table border size="small" stripe highlight-row :loading="loading" :height="tabHeight" :columns="cols" :data="selectedGroups"></Table> -->
      <vxe-table :empty-text="showLang('com.data.no')" border resizable show-overflow show-header-overflow keep-source ref="chkTable" :data="selectedGroups" :height="tabHeight"
        :row-config="{isHover: true}" :checkbox-config="{checkField: 'checked',}">
        <vxe-column type="seq" width="60"></vxe-column>
        <vxe-column field="name" :title="showLang('com.tab.title')" width="150" header-align="center">
          <template #default="{ row }">
            {{row.name}}
          </template>
        </vxe-column>
        <vxe-column field="groupName" :title="showLang('com.superior')" header-align="center">
          <template #default="{ row }">
            {{row.groupName}}
          </template>
        </vxe-column>
        <vxe-column width="260" fixed="right">
          <template #default="{ row }">
            <template v-if="row.id>0">
              <AuthButton opCode='ga' style="margin-right: 5px" @click="handleAdd(row)">{{showLang('com.op.add.sub.partition')}}</AuthButton>
              <AuthButton opCode='ge' style="margin-right: 5px" type="error" @click="handleEdit(row)">{{showLang('com.op.edit')}}</AuthButton>
              <AuthButton opCode='gd' type="error" @click="handleDelete(row)">{{showLang('com.op.del')}}</AuthButton>
            </template>
          </template>
        </vxe-column>

      </vxe-table>
      <ModalGroupEdit v-model="showEditModal" :item="editItem" />
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import ModalGroupEdit from './ModalGroupEdit'
export default {
  name: 'ConfigGroupIndex',
  components: {
    ModalGroupEdit
  },
  props: {
  },
  data() {
    return {
      tabHeight: 100,
      showEditModal: false,
      loading: false,
      editItem: {
        isAdd: true,
        index: -1,
        data: {}
      },
    }
  },
  computed: {
    ...mapState('group', ['groups', 'selectedNode', 'selectedGroups', 'topIds']),
    ...mapState('cmd', ['windowOnResize']),
    ...mapGetters('auth', ['showLang']),
  },
  watch: {
    windowOnResize() {
      this.setTableHeight();
    },
  },
  mounted: function () {
    setTimeout(this.setTableHeight, 100);
  },
  methods: {
    setTableHeight: function () {
      this.tabHeight = this.$refs.table.clientHeight
    },
    handleAdd: function (params) {
      this.editItem = { isAdd: true, index: -1, data: { groupId: params.id } };
      this.showEditModal = true;
    },
    handleEdit: function (params) {
      this.editItem = { isAdd: false, index: params.index, data: params };
      this.showEditModal = true;
    },
    handleDelete: function (params) {
      this.$Modal.confirm({
        title: this.showLang('com.op.prompt'),
        content: this.showLang('com.tips.ok.del.name',params.name),
        onOk: async () => {
          this.$store.dispatch('group/deleteGroup', params.id).then(res => {
            if (res.code == 0) {
              this.$Message.info(this.showLang('com.tips.del.success'));
              window.eventBus.$emit('groupChanged');
            } else {
              // this.$Message.error(res.data);
            }
          })
        }
      });
    },
  }
}
</script>
<style scoped>
.config-group-container {
  /* padding: 5px; */
  /* padding-right: 40px; */
  height: 100%;
  display: flex;
  flex-direction: column;
}
.search-area {
  height: 42px;
  flex: none;
  border: solid 1px #dcdee2;
  border-radius: 6px;
  margin-bottom: 5px;
  padding-top: 3px;
}
.data-area {
  /* border: solid 1px red; */
  height: 100%;
  flex: auto;
  border: solid 1px #dcdee2;
  border-radius: 6px;
}
</style>