<template>
  <Modal v-model="showModal" :mask-closable="false" draggable width="450px">
    <div slot="header">授权员验证</div>
    <Form :model="form" :label-width="120">
      <FormItem label="授权员用户">
        <Select :placeholder="showLang('save.select')" v-model="form.userId" style="width: 220px;height:32px;">
          <template v-for="(item, idx) in users">
            <Option :key="idx" :value="item.id">{{item.name}}[{{item.code}}]</Option>
          </template>
        </Select>
      </FormItem>
      <FormItem v-if="needPswd" label="请输入密码">
        <Input v-model="form.pswd" type="password"  ref="pswd"  style="width: 220px;height:32px;">
          <Button slot="append" icon="ios-eye" @click="eyeOutline()"></Button>
        </Input>
      </FormItem>
      <!-- <FormItem v-show="needUsbKey && form.userId>0" prop="expire" label="" :label-width="0">
        <Button type="success" long @click="checkUsbKey">U盾授权</Button>
      </FormItem> -->
    </Form>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">{{showLang('com.op.close')}}</Button>
      <Button v-if="!needUsbKey" type="primary" style="margin-right: 8px" @click="ok">确认</Button>
      <Button v-show="needUsbKey && form.userId>0" type="success" @click="checkUsbKey">U盾授权</Button>
    </div>
    <ModalUsbKeyCheck v-model="showUsbKeyCheckModal" :item="editItem" @checked="usbKeyChecked" />
  </Modal>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import ModalUsbKeyCheck from '@/components/sys/user/ModalUsbKeyCheck'
export default {
  name: 'ModalCheckAuth',
  components:{
    ModalUsbKeyCheck,
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    users: {
      type: Array,
      default(){ return [];}
    }
  },
  data () {
    return {
      showModal: this.value,
      showUsbKeyCheckModal: false,
      editItem: {},
      loading: false,
      form: {
        userId: 0,
        pswd: '',
      },
      needUsbKey: false,
      needPswd: false,
    }
  },
  computed: {
    ...mapGetters('auth', ['needCheckPswd','showLang']),
    ...mapState('group', ['groups', 'selectedNode', 'selectedGroups', 'rootTitle']),
    ...mapState('auth', ['customerId']),
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if(newVal){
        this.form.pswd = '';
        this.form.userId = 0;
        this.setUsbKeyNeed();
      }
    },
    showModal(val){
      this.$emit('showChanged', val);
    }
  },
  methods: {
    usbKeyChecked: function(){
      this.showModal = false;
      this.$emit('checked', {userId: this.form.userId});
    },
    checkUsbKey: function(){
      if(!this.needPswd){
        this.editItem = {type: 'check', userId: this.form.userId};
        this.showUsbKeyCheckModal = true;
        console.log('check usb key', this.form)
      }else{
        if(!this.form.pswd){
          this.$Message.warning('请输入密码');
          return;
        }
        this.$axios.post(`//${this.domains.trans}/station/auth/CheckCmdAuth`, { id: this.form.userId, pswd: this.form.pswd }).then(res => {
          if (res.code == 0) {
            this.editItem = {type: 'check', userId: this.form.userId};
            this.showUsbKeyCheckModal = true;
          }
        });
      }
    },
    setUsbKeyNeed: function(){
      this.$axios.post(`//${this.domains.trans}/sys/setting/QueryUsbKeyEnabled`, {custId: this.customerId}).then(res => {
        if(res.code == 0){
          this.needUsbKey = res.data.usb;
          if(!this.needUsbKey){
            this.needPswd = true
          }else{
            this.needPswd = res.data.pswd;
          }
        }
      });
    },
    eyeOutline() {
      this.$refs.pswd.type = 'text'
      setTimeout(() => {
        if (this.$refs.pswd) {
          this.$refs.pswd.type = 'password'
        }
      }, 3000)
      // console.log('eyeOutline')
    },
    ok: function(){
      if(!this.form.userId){
        this.$Message.warning('请选择授权员');
        return;
      }
      if(!this.form.pswd){
        this.$Message.warning('请输入密码');
        return;
      }
      this.$axios.post(`//${this.domains.trans}/station/auth/CheckCmdAuth`, { id: this.form.userId, pswd: this.form.pswd }).then(res => {
        if (res.code == 0) {
          this.showModal = false;
          this.$emit('checked', {userId: this.form.userId});
        }
      });
    },
    cancel () {
      this.showModal = false;
      // this.$refs.form.resetFields();
    },
  }
}
</script>
<style scoped>
.dev-code-list{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.demo-spin-col .circular {
  width:25px;
  height:25px;
}
.demo-spin-icon-load{
  animation: ani-demo-spin 1s linear infinite;
}
@keyframes ani-demo-spin {
  from { transform: rotate(0deg);}
  50%  { transform: rotate(180deg);}
  to   { transform: rotate(360deg);}
}
.demo-spin-col{
  height: 100px;
  position: relative;
  border: 1px solid #eee;
}
.search-data-content{
  position: relative;
  height: 300px;
  /* border: solid 1px red; */
}
.demo-drawer-footer{
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
}
.form{
  height: calc(100% - 54px);
  overflow: auto;
}
.readonly{
  background: #eee
}
</style>