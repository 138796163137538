<template>
  <div class="alarm-rule-container">
    <div class="query-data-area">
      <AuthButton opCode='pe5' size="default" v-for="(btn, idx) in btns" :key="idx" type="primary" @click="docmd(btn)" style="margin: 0 5px;">
        {{showLang(btn.lang)}}</AuthButton>
      <AuthButton opCode='pd5' size="default" type="error" @click="deleteParams" style="margin: 0 5px;">批量删除</AuthButton>
    </div>
    <div class="alarm-data-area" ref="table1">
      <u-table ref="chkTable" :data="list" style="width: 100%;" use-virtual row-key="id" border stripe :height="tabHeight" default-expand-all>
        <u-table-column type="index" header-align="center"></u-table-column>
        <u-table-column type="selection" header-align="center"></u-table-column>
        <u-table-column prop="name" :label="showLang('com.tab.title')" width="150" header-align="center">
          <template slot-scope="params">
            {{params.row.name}}
          </template>
        </u-table-column>
        <u-table-column prop="stationName" :label="showLang('station.belong')" width="150" header-align="center">
          <template slot-scope="params">
            {{params.row.stationName}}
          </template>
        </u-table-column>
        <u-table-column prop="mainName" :label="showLang('com.association.main')" width="150" header-align="center">
          <template slot-scope="params">
            {{params.row.mainName}}
          </template>
        </u-table-column>
        <u-table-column prop="count" :label="showLang('com.state.lamp.num')" width="100" header-align="center">
          <template slot-scope="params">
            {{params.row.content.count}}
          </template>
        </u-table-column>
        <u-table-column prop="power" :label="showLang('com.state.lamp.power')" width="100" header-align="center">
          <template slot-scope="params">
            {{params.row.content.power}} W
          </template>
        </u-table-column>
        <u-table-column :label="showLang('com.stat.q.correlation')" header-align="center">
          <u-table-column prop="enp" :label="showLang('com.state')" width="50" header-align="center">
            <template slot-scope="params">
              {{params.row.content.enp ? showLang('com.state.enable') : showLang('com.state.disable')}}
            </template>
          </u-table-column>
          <u-table-column prop="mainName" :label="showLang('com.ope.association.ch')" width="120" header-align="center">
            <template slot-scope="params">
              {{params.row.outputName}}
            </template>
          </u-table-column>
          <u-table-column prop="mainName" :label="showLang('com.branch.contact.need')" width="100" header-align="center">
            <template slot-scope="params">
              {{params.row.contactName}}
            </template>
          </u-table-column>
          <u-table-column prop="abc" :label="showLang('com.state.police.type')" width="80" header-align="center">
            <template slot-scope="params">
              {{params.row.content.abc ? showLang('com.lighting.I') : showLang('com.lighting.P')}}
            </template>
          </u-table-column>
          <u-table-column prop="abr" :label="showLang('com.state.police.method')" width="80" header-align="center">
            <template slot-scope="params">
              {{params.row.content.abr ? showLang('com.state.about.limit') : showLang('com.state.contrast')}}
            </template>
          </u-table-column>
          <u-table-column :label="showLang('cmd.project.A')" header-align="center">
            <u-table-column prop="par" :label="showLang('com.lighting.pr1')" width="80" header-align="center">
              <template slot-scope="params">
                {{params.row.content.par}} W
              </template>
            </u-table-column>
            <u-table-column prop="paf" :label="showLang('com.lighting.paf')" width="110" header-align="center">
              <template slot-scope="params">
                {{params.row.content.paf}}
              </template>
            </u-table-column>
            <u-table-column prop="pah" :label="showLang('com.lighting.pah')" width="80" header-align="center">
              <template slot-scope="params">
                {{params.row.content.pah}} %
              </template>
            </u-table-column>
            <u-table-column prop="cac" :label="showLang('com.ope.nav.i.ch')" width="80" header-align="center">
              <template slot-scope="params">
                {{params.row.content.cac == 0 ? '-' : `C${params.row.content.cac}`}}
              </template>
            </u-table-column>
            <u-table-column prop="cat" :label="showLang('com.lighting.cat')" width="80" header-align="center">
              <template slot-scope="params">
                {{params.row.content.cat}}
              </template>
            </u-table-column>
            <u-table-column prop="car" :label="showLang('com.lighting.rated.v.val')" width="80" header-align="center">
              <template slot-scope="params">
                {{img.val(params.row.content.car,2)}} A
              </template>
            </u-table-column>
            <u-table-column prop="cah" :label="showLang('com.data.upper.val')" width="80" header-align="center">
              <template slot-scope="params">
                {{params.row.content.cah}} %
              </template>
            </u-table-column>
            <u-table-column prop="cal" :label="showLang('com.data.lower.val')" width="80" header-align="center">
              <template slot-scope="params">
                {{params.row.content.cal}} %
              </template>
            </u-table-column>
          </u-table-column>
          <u-table-column :label="showLang('cmd.project.B')" header-align="center">
            <u-table-column prop="pbr" :label="showLang('com.lighting.pr1')" width="80" header-align="center">
              <template slot-scope="params">
                {{params.row.content.pbr}} W
              </template>
            </u-table-column>
            <u-table-column prop="pbf" :label="showLang('com.lighting.paf')" width="110" header-align="center">
              <template slot-scope="params">
                {{params.row.content.pbf}}
              </template>
            </u-table-column>
            <u-table-column prop="pbh" :label="showLang('com.lighting.pah')" width="80" header-align="center">
              <template slot-scope="params">
                {{params.row.content.pbh}} %
              </template>
            </u-table-column>
            <u-table-column prop="cbc" :label="showLang('com.ope.nav.i.ch')" width="80" header-align="center">
              <template slot-scope="params">
                {{params.row.content.cbc == 0 ? '-' : `C${params.row.content.cbc}`}}
              </template>
            </u-table-column>
            <u-table-column prop="cbt" :label="showLang('com.lighting.cat')" width="80" header-align="center">
              <template slot-scope="params">
                {{params.row.content.cbt}}
              </template>
            </u-table-column>
            <u-table-column prop="cbr" :label="showLang('com.lighting.rated.v.val')" width="80" header-align="center">
              <template slot-scope="params">
                {{img.val(params.row.content.cbr,2)}} A
              </template>
            </u-table-column>
            <u-table-column prop="cbh" :label="showLang('com.data.upper.val')" width="80" header-align="center">
              <template slot-scope="params">
                {{params.row.content.cbh}} %
              </template>
            </u-table-column>
            <u-table-column prop="cbl" :label="showLang('com.data.lower.val')" width="80" header-align="center">
              <template slot-scope="params">
                {{params.row.content.cbl}} %
              </template>
            </u-table-column>
          </u-table-column>
          <u-table-column :label="showLang('cmd.project.C')" header-align="center">
            <u-table-column prop="pcr" :label="showLang('com.lighting.pr1')" width="80" header-align="center">
              <template slot-scope="params">
                {{params.row.content.pcr}} W
              </template>
            </u-table-column>
            <u-table-column prop="pcf" :label="showLang('com.lighting.paf')" width="110" header-align="center">
              <template slot-scope="params">
                {{params.row.content.pcf}}
              </template>
            </u-table-column>
            <u-table-column prop="pch" :label="showLang('com.lighting.pah')" width="80" header-align="center">
              <template slot-scope="params">
                {{params.row.content.pch}} %
              </template>
            </u-table-column>
            <u-table-column prop="ccc" :label="showLang('com.ope.nav.i.ch')" width="80" header-align="center">
              <template slot-scope="params">
                {{params.row.content.ccc == 0 ? '-' : `C${params.row.content.ccc}`}}
              </template>
            </u-table-column>
            <u-table-column prop="cct" :label="showLang('com.lighting.cat')" width="80" header-align="center">
              <template slot-scope="params">
                {{params.row.content.cct}}
              </template>
            </u-table-column>
            <u-table-column prop="ccr" :label="showLang('com.lighting.rated.v.val')" width="80" header-align="center">
              <template slot-scope="params">
                {{img.val(params.row.content.ccr,2)}} A
              </template>
            </u-table-column>
            <u-table-column prop="cch" :label="showLang('com.data.upper.val')" width="80" header-align="center">
              <template slot-scope="params">
                {{params.row.content.cch}} %
              </template>
            </u-table-column>
            <u-table-column prop="ccl" :label="showLang('com.data.lower.val')" width="80" header-align="center">
              <template slot-scope="params">
                {{params.row.content.ccl}} %
              </template>
            </u-table-column>
          </u-table-column>
        </u-table-column>
        <u-table-column :label="showLang('com.lighting.leakage')" header-align="center">
          <u-table-column prop="enl" :label="showLang('com.state')" width="50" header-align="center">
            <template slot-scope="params">
              {{params.row.content.enl ? showLang('com.state.enable') : showLang('com.state.disable')}}
            </template>
          </u-table-column>
          <u-table-column prop="ltid" :label="showLang('com.ins.timeTable.alarm')" width="150" header-align="center">
            <template slot-scope="params">
              {{params.row.ltName}}
            </template>
          </u-table-column>
          <u-table-column prop="lc" :label="showLang('com.ins.ch.order')" width="80" header-align="center">
            <template slot-scope="params">
              {{params.row.content.lc == 0 ? '-' : `L${params.row.content.lc}`}}
            </template>
          </u-table-column>
          <u-table-column prop="lt" :label="showLang('com.lighting.cat')" width="80" header-align="center">
            <template slot-scope="params">
              {{params.row.content.lt}}
            </template>
          </u-table-column>
          <u-table-column :label="showLang('alarm.type.leakage.1')" header-align="center">
            <u-table-column prop="ll" :label="showLang('com.data.threshold')" width="80" header-align="center">
              <template slot-scope="params">
                {{params.row.content.ll}} mA
              </template>
            </u-table-column>
            <u-table-column prop="chl" :label="showLang('com.ope.nav.channel')" width="80" header-align="center">
              <template slot-scope="params">
                {{params.row.content.chl == 0 ? '-' : `K${params.row.content.chl}`}}
              </template>
            </u-table-column>
            <u-table-column prop="cmdl" :label="showLang('com.op.if.linkage')" width="80" header-align="center">
              <template slot-scope="params">
                {{params.row.content.cmdl ? showLang('com.state.enable'):showLang('com.state.disable')}}
              </template>
            </u-table-column>
            <u-table-column prop="openl" :label="showLang('com.op.linkage.switch')" width="80" header-align="center">
              <template slot-scope="params">
                {{params.row.content.openl ? showLang('com.op.linkage.on') : showLang('com.op.linkage.close')}}
              </template>
            </u-table-column>
            <u-table-column prop="expirel" :label="showLang('com.time.delay')" width="80" header-align="center">
              <template slot-scope="params">
                {{params.row.content.expirel}} min
              </template>
            </u-table-column>
          </u-table-column>
          <u-table-column :label="showLang('alarm.type.leakage.2')" header-align="center">
            <u-table-column prop="lh" :label="showLang('com.data.threshold')" width="80" header-align="center">
              <template slot-scope="params">
                {{params.row.content.lh}} mA
              </template>
            </u-table-column>
            <u-table-column prop="chh" :label="showLang('com.ope.nav.channel')" width="80" header-align="center">
              <template slot-scope="params">
                {{params.row.content.chh == 0 ? '-' : `K${params.row.content.chh}`}}
              </template>
            </u-table-column>
            <u-table-column prop="cmdh" :label="showLang('com.op.if.linkage')" width="80" header-align="center">
              <template slot-scope="params">
                {{params.row.content.cmdh ? showLang('com.state.enable'):showLang('com.state.disable')}}
              </template>
            </u-table-column>
            <u-table-column prop="openh" :label="showLang('com.op.linkage.switch')" width="80" header-align="center">
              <template slot-scope="params">
                {{params.row.content.openh ? showLang('com.op.linkage.on') : showLang('com.op.linkage.close')}}
              </template>
            </u-table-column>
            <u-table-column prop="expireh" :label="showLang('com.time.delay')" width="80" header-align="center">
              <template slot-scope="params">
                {{params.row.content.expireh}} min
              </template>
            </u-table-column>
          </u-table-column>
          <u-table-column :label="showLang('alarm.type.leakage.3')" header-align="center">
            <u-table-column prop="lx" :label="showLang('com.data.threshold')" width="80" header-align="center">
              <template slot-scope="params">
                {{params.row.content.lx}} mA
              </template>
            </u-table-column>
            <u-table-column prop="chx" :label="showLang('com.ope.nav.channel')" width="80" header-align="center">
              <template slot-scope="params">
                {{params.row.content.chx == 0 ? '-' : `K${params.row.content.chx}`}}
              </template>
            </u-table-column>
            <u-table-column prop="cmdx" :label="showLang('com.op.if.linkage')" width="80" header-align="center">
              <template slot-scope="params">
                {{params.row.content.cmdx ? showLang('com.state.enable'):showLang('com.state.disable')}}
              </template>
            </u-table-column>
            <u-table-column prop="openx" :label="showLang('com.op.linkage.switch')" width="80" header-align="center">
              <template slot-scope="params">
                {{params.row.content.openx ? showLang('com.op.linkage.on') : showLang('com.op.linkage.close')}}
              </template>
            </u-table-column>
            <u-table-column prop="expirex" :label="showLang('com.time.delay')" width="80" header-align="center">
              <template slot-scope="params">
                {{params.row.content.expirex}} min
              </template>
            </u-table-column>
          </u-table-column>
        </u-table-column>
        <u-table-column :label="showLang('com.anti.theft')" header-align="center">
          <u-table-column prop="ens" :label="showLang('com.state')" width="50" header-align="center">
            <template slot-scope="params">
              {{params.row.content.ens ? showLang('com.state.enable') : showLang('com.state.disable')}}
            </template>
          </u-table-column>
          <u-table-column prop="sc" :label="showLang('com.ins.ch.order')" width="80" header-align="center">
            <template slot-scope="params">
              {{params.row.content.sc == 0 ? '-' : `C${params.row.content.sc}`}}
            </template>
          </u-table-column>
          <u-table-column prop="sh" :label="showLang('com.data.threshold')" width="80" header-align="center">
            <template slot-scope="params">
              {{params.row.content.sh}} s
            </template>
          </u-table-column>
          <u-table-column prop="chs" :label="showLang('com.ope.nav.channel')" width="80" header-align="center">
            <template slot-scope="params">
              {{params.row.content.chs == 0 ? '-' : `K${params.row.content.chs}`}}
            </template>
          </u-table-column>
          <u-table-column prop="cmds" :label="showLang('com.op.if.linkage')" width="80" header-align="center">
            <template slot-scope="params">
              {{params.row.content.cmds ? showLang('com.state.enable'):showLang('com.state.disable')}}
            </template>
          </u-table-column>
          <u-table-column prop="opens" :label="showLang('com.op.linkage.switch')" width="80" header-align="center">
            <template slot-scope="params">
              {{params.row.content.opens ? showLang('com.op.linkage.on') : showLang('com.op.linkage.close')}}
            </template>
          </u-table-column>
          <u-table-column prop="expires" :label="showLang('com.time.delay')" width="80" header-align="center">
            <template slot-scope="params">
              {{params.row.content.expires}} min
            </template>
          </u-table-column>
        </u-table-column>
        <u-table-column width="120" fixed="right">
          <template slot="header">
            <AuthButton opCode='pa5' style="margin-right: 5px" @click="handleAdd">{{showLang('com.op.add')}}</AuthButton>
            <AuthButton opCode='pv5' style="margin-right: 5px" @click="getList">{{showLang('com.op.refresh')}}</AuthButton>
          </template>
          <template slot-scope="scope">
            <AuthButton opCode='pe5' style="margin-right: 5px" @click="handleEdit(scope)">{{showLang('com.op.edit')}}</AuthButton>
            <AuthButton opCode='pd5' type="error" @click="handleDelete(scope)">{{showLang('com.op.del')}}</AuthButton>
          </template>
        </u-table-column>
      </u-table>
      <ModalBranchEdit v-model="showEditModal" :item="editItem" :stations="selectedStations" @saved="itemSaved" />
      <ModalArgEdit v-model="showArgModal" :item="editItem" @saved="argsSaved" />
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import ModalBranchEdit from './ModalBranchEdit'
import ModalArgEdit from '../muti/ModalArgEdit'
export default {
  name: 'ConfigBranchIndex',
  components: {
    ModalBranchEdit,
    ModalArgEdit,
  },
  props: {
  },
  data() {
    return {
      curTab: 'hour24',
      list: [],
      tabHeight: 600,
      editItem: { isAdd: true, data: {} },
      showEditModal: false,
      showArgModal: false,
      btns: [
        { code: 'updatePowerArgs', name: '设定功率参数', lang: 'com.set.power.parameters' },
        { code: 'updateCurrentRated', name: '设定电流倍率', lang: 'com.set.i.parameters' },
        { code: 'updateCurrentRate', name: '设定额定电流', lang: 'com.set.rated.current' },
        { code: 'updateLeakageArgs', name: '设定漏电参数', lang: 'com.set.leakage.parameters' },
        { code: 'updateSteelArgs', name: '设定防盗参数', lang: 'com.set.anti.parameters' },
      ]
    }
  },
  computed: {
    ...mapGetters('auth', ['showLang']),
    ...mapState('cmd', ['windowOnResize', 'cmds']),
    ...mapState('common', ['weeks', 'productCodes', 'alarmTypes']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
    selectedStations: function () {
      return this.selectedDevices.filter(p => p.type == this.productCodes.station);
    },
    selectedStationIds: function () {
      let pids = this.selectedDevices.filter(p => p.type == this.productCodes.station).map(p => { return p.id });
      return pids;
    },
  },
  watch: {
    windowOnResize() {
      this.setTableHeight();
    },
    selectedNode() {
      this.getList();
    }
  },
  mounted: function () {
    setTimeout(this.setTableHeight, 100);
    this.getList();
  },
  destroyed: function () {
  },
  methods: {
    docmd: function (params) {
      let chks = this.$refs.chkTable.getCheckboxRecords();
      if (chks.length == 0) {
        this.$Message.warning('请选择要配置的记录');
        return;
      }
      this.editItem = params;
      this.showArgModal = true;
    },
    argsSaved: function (params) {
      // console.log('get cmd args', params);
      let chks = this.$refs.chkTable.getCheckboxRecords();
      if (chks.length == 0) {
        this.$Message.warning('请选择要配置的记录');
        return;
      }
      let list = chks.map(p => p.id);
      this.$Modal.confirm({
        title: "提示",
        content: `确定要更新已选的[${list.length}]条记录参数吗?`,
        onOk: async () => {
          this.$axios.post(`//${this.domains.trans}/station/config/${params.code}`, { list: list, args: params }).then(res => {
            if (res.code != 0) {
              // this.$Message.error(res.data);
            } else {
              this.getList();
            }
          });
        }
      });
    },
    setTableHeight: function () {
      this.tabHeight = this.$refs.table1.clientHeight
    },
    handleAdd: function () {
      this.editItem = { isAdd: true, data: {} };
      this.showEditModal = true;
    },
    handleEdit: function (params) {
      this.editItem = { isAdd: false, data: params.row };
      this.showEditModal = true;
    },
    itemSaved: function () {
      this.getList();
    },
    deleteParams: function () {
      let chks = this.$refs.chkTable.getCheckboxRecords();
      if (chks.length == 0) {
        this.$Message.warning(this.showLang('com.tips.sel.del.record'));
        return;
      }
      let list = chks.map(p => p.id);
      this.$Modal.confirm({
        title: this.showLang('com.op.prompt'),
        content: this.showLang('com.tips.ok.del.record'),
        onOk: async () => {
          this.$axios.post(`//${this.domains.trans}/station/config/DeleteDevice`, { list: list }).then(res => {
            if (res.code == 0) {
              this.getList();
            }
          });
        }
      });
    },
    handleDelete: function (params) {
      this.$Modal.confirm({
        title: this.showLang('com.op.prompt'),
        content: this.showLang('com.tips.ok.del.name', params.row.name),
        onOk: async () => {
          this.$axios.post(`//${this.domains.trans}/station/config/DeleteDevice`, { list: [params.row.id] }).then(res => {
            if (res.code == 0) {
              this.getList();
            }
          });
        }
      });
    },
    getList: function () {
      // console.log('windowOnResize', this.selectedStationIds.join(','))
      let groupId = 0, stationId = 0;
      if (this.selectedNode.type == this.productCodes.station) {
        stationId = this.selectedNode.id;
      } else {
        groupId = this.selectedNode.id;
      }
      this.$axios.post(`//${this.domains.trans}/station/config/QueryBranch`, { groupId, stationId }).then(res => {
        if (res.code == 0) {
          this.$set(this, 'list', res.data);
        }
      });
    },
  }
}
</script>
<style scoped>
.alarm-rule-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  /* padding: 10px; */
  /* padding-right: 40px; */
  /* border: solid 1px red; */
}
.query-data-area {
  height: 45px;
  flex: none;
  display: flex;
  align-items: center;
  border: solid 1px rgba(143, 138, 138, 0.452);
  margin-bottom: 5px;
}
.alarm-data-area {
  /* border: solid 1px red; */
  height: 100px;
  flex: auto;
  /* border: solid 1px #DCDEE2; */
  border-radius: 6px;
}
.station-alarm-tabs {
  height: 30px;
  flex: none;
  display: flex;
}
.station-alarm-tab {
  padding: 3px 8px;
  margin: 3px 3px 0 3px;
  border: solid 1px lightgray;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
  background-color: white;
}
.tab-active {
  border: solid 1px rgb(135, 159, 224);
  background-color: rgb(135, 159, 224);
  color: white;
}
.station-alarm-tabcontent {
  height: 500px;
  flex: auto;
  /* border: solid 1px red; */
}
/* .box-body{
  height: 0;
  flex: auto;
  margin-top: 5px;
  border: solid 1px red;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: relative;
}
.box-body::-webkit-scrollbar {
  display: none;
} */
</style>