<template>
  <Modal v-model="showModal" :mask-closable="false" width="500px">
    <div slot="header">{{showLang('com.but.edit.cable.info')}}</div>
    <Form ref="form" :model="form" :rules="rules" :label-width="110">
      <FormItem prop="code" :label="showLang('cable.code.title')" style="margin-right: 15px">
        <div class="flex-row">
          <Input type="text" v-model="form.code" :placeholder="showLang('cable.code.title')"></Input>
        </div>
      </FormItem>
      <FormItem prop="name" :label="showLang('com.tab.title')" style="margin-right: 15px">
        <div class="flex-row">
          <Input type="text" v-model="form.name" :placeholder="showLang('com.tab.title')"></Input>
        </div>
      </FormItem>
      <FormItem prop="stationId" :label="showLang('com.site.affiliated')" style="margin-right: 15px">
        <Select :placeholder="showLang('save.select')" v-model="form.stationId" @on-change="stationChanged">
          <Option v-for="(s, idx) in stations" :key="idx" :value="s.id">{{s.name}}</Option>
        </Select>
      </FormItem>
      <FormItem prop="branchId" :label="showLang('com.dev.associated.branch')" style="margin-right: 15px">
        <Select :placeholder="showLang('save.select')" v-model="form.branchId">
          <template v-for="(item, idx) in branchs">
            <Option :value="parseInt(item.id)" :key="idx">{{item.name}}</Option>
          </template>
        </Select>
      </FormItem>
      <FormItem prop="meteria" :label="showLang('cable.meteria.title')" style="margin-right: 15px">
        <div class="flex-row">
          <Input type="text" v-model="form.meteria" :placeholder="showLang('cable.meteria.title')"></Input>
        </div>
      </FormItem>
      <FormItem prop="coreNum" :label="showLang('cable.core.num')" style="margin-right: 15px">
        <div class="flex-row">
          <Input type="number" v-model="form.coreNum" :placeholder="showLang('cable.core.num')"></Input>
        </div>
      </FormItem>
      <FormItem prop="sectionalArea" :label="showLang('cable.sectional.area')" style="margin-right: 15px">
        <div class="flex-row">
          <Input type="number" v-model="form.sectionalArea" :placeholder="showLang('cable.sectional.area')"></Input>
        </div>
      </FormItem>
      <FormItem prop="length" :label="showLang('cable.length.title')" style="margin-right: 15px">
        <div class="flex-row">
          <Input type="number" v-model="form.length" :placeholder="showLang('cable.length.title')"></Input>
        </div>
      </FormItem>
      <FormItem prop="buildDate" :label="showLang('cable.build.date')">
        <DatePicker ref="buildDate" type="date" format="yyyy-MM-dd" v-model="form.buildDate" :placeholder="showLang('cable.build.date')" :editable="false"></DatePicker>
      </FormItem>
    </Form>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">{{showLang('com.op.cancel')}}</Button>
      <Button type="primary" :loading="loading" @click="ok">{{showLang('com.op.save')}}</Button>
    </div>
    <!-- <ModalSelectGroup v-model="showSelectModal" @newGroupSelected="newGroupSelected" /> -->
  </Modal>
</template>
<script>
import { mapState,mapGetters } from 'vuex'
export default {
  name: 'ModalLineEdit',
  components:{
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default(){ return {};}
    },
    stations: {
      type: Array,
      default(){ return [];}
    },
  },
  data () {
    return {
      showModal: this.value,
      // showSelectModal: false,
      loading: false,
      isAdd: false,
      types: [],
      groupName: '',
      branchs: [],
      form: {
        id: 0,
        stationId: 0, //关联站点
        branchId: 0, //关联支路
        code: '', //线缆编号
        name: '', //线缆名称
        meteria: '', //材质
        coreNum: 0, //芯数
        sectionalArea: 0, //每芯截面
        length: 0, //长度
        buildDate: '', //安装日期
      },
      rules: {
      },
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if(newVal){
        // console.log('modal level edit mounted', this.item)
        this.isAdd = this.item.isAdd;
        if(!this.isAdd){
          this.form.id = this.item.data.id;
          this.form.stationId = this.item.data.stationId;
          this.form.name = this.item.data.name;
          this.form.code = this.item.data.code;
          this.form.branchId = this.item.data.branchId;
          this.form.meteria = this.item.data.meteria;
          this.form.coreNum = this.item.data.coreNum;
          this.form.sectionalArea = this.item.data.sectionalArea;
          this.form.length = this.item.data.length;
          this.form.buildDate = this.item.data.buildDate;
          
          this.stationChanged();
        }else{
          this.form.id = 0;
          this.form.stationId = 0;
          this.form.branchId = 0;
          this.form.name = '';
          this.form.code = '';
          this.form.meteria = '';
          this.form.coreNum = 0;
          this.form.sectionalArea = 0;
          this.form.length = 0;
          this.form.buildDate = new Date().format('yyyy-MM-dd');
        }
      }
    },
    showModal(val){
      this.$emit('showChanged', val);
    },
  },
  computed: {
    ...mapGetters('auth', ['showLang']),
    ...mapState('common', ['connectTypes']),
    ...mapState('common', ['currentChannelCount', 'leakageChannelCount', 'steelChannelCount', 'weeks', 'deviceTypes', 'productCodes', 'powerBoxTypes']),
    boxType: function () {
      return function (deviceId) {
        // if (!this.selectedStation) return 'none';
        if (deviceId <= 0) return 'none';
        let devs = this.devices.filter(p => p.id == deviceId);
        if (devs.length == 0) return 'none';
        return devs[0].type;
      }
    },
  },
  mounted: function(){
  },
  methods: {
    stationChanged: function(){
      // this.form.content.mid = 0;
      if(this.form.stationId <= 0){
        // this.$set(this, 'contacts', []);
        this.$set(this, 'branchs', []);
      }else{
        this.$axios.post(`//${this.domains.trans}/station/config/QueryBranch`, {groupId: 0, stationId: this.form.stationId}).then(res => {
          if(res.code != 0){
            // this.$Message.error(res.data);
          }else{
            this.$set(this, 'branchs', res.data);
            // if(callback && `${typeof callback}` == 'function')callback();
          }
        });
      }
    },
    ok: async function () {
      if(this.form.name.trim().length == 0){
        this.$Message.warning(this.showLang('save.name'));
        return;
      }
      if(this.form.stationId <= 0){
        this.$Message.warning(this.showLang('com.save.err.station'));
        return;
      }
      if(this.form.branchId <= 0){
        this.$Message.warning(this.showLang('sava.dev.associated.branch'));
        return;
      }
      this.$axios.post(`//${this.domains.trans}/station/config/SaveLine`, this.form).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$Message.info(this.showLang('com.tips.save'));
          this.showModal = false;
          this.$emit('saved', {isAdd: this.isAdd, data: res.data});
        }
      })
    },
    cancel () {
      this.showModal = false;
      // this.$refs.form.resetFields();
    },
  }
}
</script>
<style scoped>
.form-item-group{
  border: solid 1px rgba(129, 124, 124, 0.377);
  border-radius: 12px;
  margin-bottom: 15px;
}
.group-header{
  /* border: solid 1px blue; */
  background-color: rgba(221, 216, 216, 0.808);
  margin-bottom: 15px;
  height: 35px;
  border-radius: 12px 12px 0 0;
  line-height: 35px;
  font-weight: bold;
  padding: 0 10px;
}
  .demo-drawer-footer{
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    border-top: 1px solid #e8e8e8;
    padding: 10px 16px;
    text-align: right;
    background: #fff;
  }
  .form{
    height: calc(100% - 54px);
    overflow: auto;
  }
  .readonly{
    background: #eee
  }
</style>